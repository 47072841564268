<template>
<div class="container">
  <img src="../../../assets/images/activity/success.png" alt="">
  <div class="container_success">{{ type==="1"?"签到":"签退" }}成功</div>
  <div class="container_activity">
    <div class="container_activity_des">您参与的活动</div>
    <div class="container_activity_name">{{ data?.vlActivity?.name }}</div>
  </div>
  <div class="container_line"></div>
  <div class="container_address">
    <div class="container_address_des">{{ type==="1"?"签到":"签退" }}地点</div>
    <div class="container_address_name">{{ data?.vlActivity?.addressDetail }}</div>
  </div>
  <div class="container_time">
    <div class="container_time_des">{{ type==="1"?"签到":"签退" }}时间</div>
    <div class="container_time_name">{{ data?.createTime }}</div>
  </div>
</div>
</template>

<script setup>
import {useRoute} from "vue-router";
import {ref} from "vue";
import {getSign, getSignOff} from "@/api/activity";
const route = useRoute();
const id = ref(null);
const type = ref("");
const data = ref({});
id.value = route.query.sid;
type.value = route.query.type;
const getInfo=async ()=>{
  if(type.value==="1"){
    let response =await getSign(id.value);
    data.value=response.data
  }else {
    let response =await getSignOff(id.value);
    data.value=response.data
  }

}
getInfo();
</script>

<style lang="scss" scoped>
.container{
  padding: 50px 30px 197px 30px;
  display: flex;
  flex-direction: column;
  img{
    width: 60px;
    height: 60px;
    margin-left: 127px;
  }
  &_success{
    margin-top: 25px;
    line-height: 29px;
    color: rgba(16, 16, 16, 100);
    font-size: 20px;
    text-align: center;
    font-family: Microsoft Yahei;
  }
  &_activity{
    margin-top: 50px;
    line-height: 24px;
    color: rgba(33, 33, 33, 100);
    font-size: 14px;
    text-align: left;
    font-family: Arial;
    &_des{

    }
    &_name{
      font-weight: bolder;
    }
  }
  &_line{
    height: 1px;
    line-height: 20px;
    background-color: rgba(238, 238, 238, 100);
    text-align: center;
    margin-top: 20px;
  }
  &_address{
    margin-top: 30px;
    display: flex;
    &_des{
      width: 57px;
      height: 20px;
      line-height: 20px;
      color: rgba(158, 158, 158, 100);
      font-size: 14px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
    &_name{
      height: 42px;
      line-height: 20px;
      margin-left: 16px;
      width: 242px;
      color: rgba(66, 66, 66, 100);
      font-size: 14px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
  }
  &_time{
    margin-top: 20px;
    display: flex;
    line-height: 20px;
    height: 20px;
    &_des{
      width: 57px;
      color: rgba(158, 158, 158, 100);
      font-size: 14px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
    &_name{
      margin-left: 16px;
      color: rgba(66, 66, 66, 100);
      font-size: 14px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
  }
}
</style>
