<!---兑换记录-->
<template>
  <div class="container">
    <div class="container_top">
      <div class="container_top_firstline">
        <span class="container_top_firstline_deco"><img
            src="../../assets/images/center/deco.png"
            alt=""
          ></span><span class="container_top_firstline_title">已兑换积分</span>
      </div>
      <div class="container_top_secondline">
        {{ score }}
      </div>
    </div>

    <div class="container_main">
      <div class="container_main_title">商品兑换明细</div>
      <div class="container_main_divider">
        <van-divider />
      </div>
      <div class="container_main_list">
        <!--  列表  -->
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="finishedText"
            @load="onLoad"
            class="list"
          >
            <div
              class="container_main_list_item"
              v-for="item in list"
              :key="item"
            >
              <div class="container_main_list_item_pic">
                <img
                  :src="item.images.length>0 ? item.images[0].url: pic"
                  alt=""
                >
              </div>
              <div class="container_main_list_item_info">
                <div class="container_main_list_item_info_title">
                  {{item.name}}
                </div>
                <div
                  class="container_main_list_item_info_price"
                  v-if="item.payType==='1'"
                >
                  {{item.isRebate==='0' ? item.discountPrice : item.originalPrice}}元
                </div>
                <div
                  class="container_main_list_item_info_price"
                  v-else-if="item.payType==='2'"
                >
                  {{item.score}}积分
                </div>
                <div
                  class="container_main_list_item_info_price"
                  v-else-if="item.payType==='3'"
                >
                  {{item.isRebate==='0' ? item.discountPrice : item.originalPrice}}元+{{item.score}}积分
                </div>
                <div class="container_main_list_item_info_date">时间：{{item.orderCreateTime}}兑换</div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
        <van-empty
          description="暂无信息"
          v-if="isEmpty"
        />
      </div>
    </div>
  </div>
</template>
<script setup name="ExchangeRecord">
import { ref } from "@vue/reactivity";
import { getMyExchange, getMyExchangeScore } from "@/api/shop";
const score = ref({});
const getScore = async () => {
  let response = await getMyExchangeScore();
  score.value = response.msg
}
getScore();
const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
// const allchecked = ref([]);
// const checkboxGroup = ref(null);
const query = ref({ pageSize: 10, pageNum: 1 })
const isEmpty = ref(false);
query.value = { pageNum: 1, pageSize: 10 }
const finishedText = ref('');
const pic = ref('.../../assets/images/shop/g2.png');
const onRefresh = () => {
  query.value.pageNum = 1
  // 清空列表数据
  list.value = []
  finished.value = true
  finishedText.value = ''
  refreshing.value = true;
  onLoad();
};
const onLoad = async () => {
  if (refreshing.value) {
    list.value = [];
    refreshing.value = false;
  }
  let response = await getMyExchange(query.value);
  loading.value = false
  refreshing.value = false
  console.log(response.rows)
  if (response.rows.length > 0) {

    list.value = list.value.concat(response.rows)
    query.value.pageNum++
    finished.value = false
    list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
  } else {
    // 数据全部加载完成
    finished.value = true
  }
  isEmpty.value = list.value.length === 0;
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 100px) !important;
  overflow-y: scroll;
  &_top {
    padding: 15px 15px 15px 15px;
    &_firstline {
      display: flex;
      flex-direction: row;
      align-items: center;
      &_deco {
        margin-right: 5px;
      }
      &_deco img {
        height: 14px;
      }
      &_title {
        font-weight: bold;
        font-size: 12px;
        color: #212121;
      }
    }
    &_secondline {
      font-size: 28px;
      color: #f4be48;
      margin-top: 10px;
    }
  }
  &_main {
    margin: 5px 10px 10px 10px;
    padding: 15px 15px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    &_title {
      color: #212121;
      font-size: 16px;
      font-weight: bold;
    }
    &_list {
      &_item {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        padding: 10px 10px;
        background: #fafafa;
        border-radius: 6px;
        &_pic {
          margin-right: 10px;
        }
        &_pic img {
          height: 80px;
          vertical-align: middle;
        }
        &_info {
          display: flex;
          flex-direction: column;
          &_title {
            font-size: 14px;
            color: #212121;
            flex: 1;
          }
          &_price {
            color: #bdbdbd;
            font-size: 10px;
          }
          &_date {
            color: #bdbdbd;
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
