<template>
    <div class="container">
        <div class="container_search"><van-search v-model="searchName" placeholder="请输入搜索关键词"  @search="onSearch"/></div>
        <div class="container_content">
           <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                v-if="!isempty"
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                class="list"
            >
            <template v-for="item in list" :key="item">
                <GoodsItem :item="item"></GoodsItem>
            </template>
            </van-list>
            </van-pull-refresh>
            <van-empty
                description="暂无信息"
                v-if="isempty"
            />
        </div>
    </div>
</template>
<script setup name="ShopSearch">
import {reactive, ref, toRefs} from "@vue/reactivity";
import {getShopGoods } from "@/api/shop"
import GoodsItem from "../../components/GoodsItem.vue";
import {Toast} from "vant";
import {useStore} from "vuex";
const store = useStore();
const isempty = ref(false)
const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const query = reactive({ queryParams: { pageSize: 10, pageNum: 1 } })
const { queryParams } = toRefs(query)
const searchName = ref();
const onRefresh = () => {
  refreshing.value = true;
  onLoad();
};

const onSearch = () => {
  if(searchName.value == null || searchName.value == ''){
    Toast("请输入搜索内容");
    return;
  }
  queryParams.value.pageNum = 1;
  onLoad();
}


const onLoad = async () => {
  if (refreshing.value) {
    list.value = [];
    refreshing.value = false;
  }
  queryParams.value.stationId = store.state.stationId == "undefined" ? "" : store.state.stationId;
  queryParams.value.selectName = searchName.value;
  let result = await getShopGoods(queryParams.value);
  if (result?.code === 200) {
    loading.value = false;
    list.value = result?.rows;
    finished.value = true;
    isempty.value = false;
    if (list.value.length < result?.total) {
      queryParams.value.pageNum++;
    }
  } else if (result?.code === 410) {
    loading.value = false;
    list.value = [];
    Toast("未查询到相关信息");
    finished.value = true;
    isempty.value = true;
  } else {
    loading.value = false;
    finished.value = true;
    isempty.value = true;
  }
}
</script>

<style lang="scss" scoped>
.container{
    height: calc(100vh - 50px)!important;
    overflow-y: scroll;
    &_search{

    }
    &_content{
        padding: 5px 10px;
    }
}
</style>
