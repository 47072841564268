<template>
  <div class="container">
    <div class="container_swipper">
      <van-swipe
        :autoplay="3000"
        height="375"
      >
        <van-swipe-item
          v-for="image in goods.images"
          :key="image"
        >
          <img class="swipeImg" :src="image.url" />
        </van-swipe-item>
        <template #indicator="{ active, total }">
          <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
        </template>
      </van-swipe>

    </div>
    <div class="container_main">
      <div class="container_main_top">
        <div class="container_main_top_title">

          <div class="container_main_top_title_left" v-if="goods.payType==='1'">
            <span> &yen;{{goods.isRebate==="1" ? goods.originalPrice : goods.discountPrice}}</span>
          </div>
          <div class="container_main_top_title_left" v-else-if="goods.payType==='2'">
            <div>{{goods.score}}<span class="container_main_top_title_tag">积分</span></div>
          </div>
          <div class="container_main_top_title_left" v-else-if="goods.payType==='3'">
            <div>{{goods.score}}<span class="container_main_top_title_tag">积分+</span></div> <span> &yen;{{goods.isRebate==="1" ? goods.originalPrice : goods.discountPrice}}</span>
          </div>

          <div class="container_main_top_title_right">{{goods.buyCount}}人兑换{{goods.exchangeNum}}{{goods?.vlUnit?.unit}}</div>
        </div>

        <div class="container_main_top_info">
          <span class="container_main_top_info_bg">3星会员享9折优惠</span><span class="container_main_top_info_tag">1.99</span>
        </div>
      </div>
      <div class="container_main_detail">
        <div class="container_main_detail_title">{{goods.name}}</div>
        <div class="container_main_detail_supporter"> <span><img
              src="../../assets/images/shop/supporter.png"
              alt=""
            > </span><span class="container_main_detail_supporter_tag">赞助商</span>
        </div>
        <div class="container_main_detail_company">
          {{goods.support}}
        </div>
        <div class="container_main_detail_divider">
          <van-divider />
        </div>
        <div class="container_main_detail_specs">
          <img
            src="../../assets/images/shop/specs.png"
            alt=""
          />
          <span class="container_main_detail_specs_tag">商品详情</span>
        </div>
        <div class="container_main_detail_row">
          {{goods.description}}
        </div>

      </div>

    </div>

    <div class="container_bottom">
      <van-action-bar>
        <van-action-bar-icon
          icon="cart-o"
          text="购物车"
          :badge="cartNum"
          @click="toShopCart()"
        />
<!--        <van-action-bar-icon
          icon="star"
          text="已收藏"
          color="#ff5000"
        />-->
        <van-action-bar-button
          type="warning"
          text="加入购物车"
          @click="showPopUp"
        />
        <van-action-bar-button
          color="#FF9100"
          type="danger"
          text="立即下单"
          @click="toPay"
        />
      </van-action-bar>
    </div>

  </div>
  <van-popup
    v-model:show="show"
    round
    closeable
    position="bottom"
    :style="{ height: '40%' }"
  >
    <div class="cartItem">
      <div class="cartItem_title">添加到购物车</div>
      <div class="container_item">
        <div class="container_item_main">
          <div class="container_item_main_pic">
            <van-image
              :src="goods?.images[0].url"
              alt=""
              height="60px"
              width="60px"
            />
          </div>
          <div class="container_item_main_info">
            <div class="container_item_main_info_title">{{goods.name}}</div>

            <template v-if="goods.payType==='1'">
            <div class="container_item_main_info_price"  >&yen;{{goods?.isRebate==='1'? goods?.originalPrice : goods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{goods?.vlUnit?.unit}}</span></div>
            <div class="container_item_main_score"  >
              <div class="container_item_main_score_desc" v-show="false">积分：- 15</div>
              <div class="container_item_main_score_handle">
                <van-stepper
                  v-model="goods.num"
                  button-size="22"
                  disable-input
                />
              </div>
            </div>
            </template>

            <template v-else-if="goods.payType==='2'">
            <div class="container_item_main_info_price"  v-if="1>2">&yen;{{goods?.isRebate==='1'? goods?.originalPrice : goods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{goods?.vlUnit?.unit}}</span></div>
            <div class="container_item_main_score"  >
              <div class="container_item_main_score_desc">积分：- {{goods.score}}</div>
              <div class="container_item_main_score_handle">
                <van-stepper
                  v-model="goods.num"
                  button-size="22"
                  disable-input
                />
              </div>
            </div>
            </template>
            <template v-else-if="goods.payType==='3'">
            <div class="container_item_main_info_price">&yen;{{goods?.isRebate==='1'? goods?.originalPrice : goods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{goods?.vlUnit?.unit}}</span></div>
            <div class="container_item_main_score"  >
              <div class="container_item_main_score_desc">积分：- {{goods.score}}</div>
              <div class="container_item_main_score_handle">
                <van-stepper
                  v-model="goods.num"
                  button-size="22"
                  disable-input
                />
              </div>
            </div>
            </template>


          </div>
        </div>
      </div>
      <div class="container_bottom">
        <van-button
          type="warning"
          size="large"
          color="#FFD11A"
          @click="addToCart"
        >确认添加</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script setup name="ShopDetail">
//todo 1. 根据不同的付款方式显示不同的付款类型 2. 支付时要根据不同的付款类型默认选中不同的支付类型 3. 后端处理要根据不同的付款类型进行支付的处理
import { reactive, ref, toRefs } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { getShopGoodsById, addCart, getCart } from "@/api/shop"
import { nextTick } from "@vue/runtime-core";
const route = useRoute();
const router = useRouter();
const goodsId = route.query.id;
console.log("route.query.id==", route.query.id)
const data = reactive({ goods: {} })
const cartList = ref([])
const show = ref(false);
const cartNum = ref(0)
const active = ref(0)
const total = ref(0)
active.value = 0
total.value = 3
const payList = ref([]);

//获取商品详情
const getGoodsByid = async (id) => {
  let result = await getShopGoodsById(id);
  data.goods = result.data
}
getGoodsByid(goodsId);

//获取购物车列表
const getCartList = async () => {
  let result = await getCart();
  console.log("cartlist===", result)
  cartList.value = result.rows
  cartNum.value = cartList.value.length
}
getCartList();


const { goods } = toRefs(data)

const showPopUp = () => {
  show.value = true
}

//加入购物车
//let goodsNum = 1;
const addToCart = async () => {
  let cart = {
    goodsId: goods.value.id,
    num: goods.value.num ?? 1
  }
  let result = await addCart(cart);
  if (result.code === 200) {
    console.log(result)
    show.value = false
    getCartList();
  }
  return result.data
}
//到购物车界面
const toShopCart = () => {
  router.push({ path: "shopCart" })
}
//到支付界面
const toPay = async () => {
  let cartGoods = await addToCart();
  cartGoods.check = true
  console.log("cartGoods===",cartGoods)
  payList.value.push(cartGoods)
  let price = 0;
  let score = 0;
  console.log("payList.value", payList.value)
  for (let item of payList.value) {
      console.log("item.num===",item)
    if(item?.vlShopGoods?.payType==="1"){
      price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
    }else if(item?.vlShopGoods?.payType==="2"){
      score += item?.vlShopGoods?.score * item.num
    } else{
      price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
      score += item?.vlShopGoods?.score * item.num
    }
  }
  nextTick(()=>{
    if(payList.value!=null && payList.value.length>0){
      console.log("totalprice===",price)
      console.log("totalscore===",score)
      console.log("paylist===",JSON.stringify(payList.value))
      router.push({ name: "ShopPay", params: { list: JSON.stringify(payList.value),totalPrice: price,totalScore: score } })
    }
  })


}

</script>

<style lang="scss" scoped>
.swipeImg{
  width: 100%;
  height: 100%;
}
.container {
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
  &_swipper {
    height: 375px;
  }
  &_main {
    margin-top: 15px;
    padding: 15px 10px 10px 10px;
    &_top {
      background: #3a3a3c;
      padding: 15px 15px 15px 15px;
      border-radius: 8px 8px 0px 0px;
      &_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &_tag {
          font-size: 14px;
        }
        &_left {
          display: flex;
          flex-direction: row;
          font-size: 24px;
          color: #ffe9c0;
        }
        &_right {
          color: #ffffff;
          font-size: 14px;
        }
      }
      &_info {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 10px;
        color: #ffffff;
        &_bg {
          color: #ff9100;
          padding: 3px 4px 3px 4px;
          background: #ffffff;
          border-radius: 2px;
        }
      }
    }
    &_detail {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 6px;
      padding: 24px 15px 24px 15px;
      margin-top: -8px;
      z-index: 1;
      &_title {
        font-size: 16px;
        color: #212121;
      }
      &_supporter {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        &_tag {
          font-size: 12px;
          color: #212121;
        }
      }
      &_supporter img {
        width: 14px;
        margin-right: 5px;
      }
      &_company {
        margin: 20px 0 10px 0;
        font-size: 14px;
        color: #212121;
      }
      &_divider {
        margin-bottom: 10px;
      }
      &_specs {
        display: flex;
        flex-direction: row;
        &_tag {
          font-size: 12px;
          color: #212121;
          font-weight: bold;
        }
      }
      &_specs img {
        height: 14px;
        margin-right: 5px;
      }

      &_row {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        &_left {
          margin-right: 20px;
          color: #9e9e9e;
          font-size: 14px;
          flex: 2;
        }
        &_right {
          font-size: 14px;
          color: #424242;
          justify-self: flex-start;
          flex: 8;
        }
      }
    }

    &_address {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 6px;
      padding: 24px 15px 24px 15px;
      &_title {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        color: #212121;
      }
      &_title img {
        margin-right: 5px;
        height: 14px;
      }
      &_info {
        margin-top: 20px;
      }
    }
  }
  &_bottom {
    padding: 25px 20px;
  }
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 10px;
  background: #9e9e9e;
  color: #ffffff;
  border-radius: 12px 0px 12px 12px;
  padding: 5px 10px 5px 10px;
}

.cartItem {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
  &_title {
    font-size: 16px;
    color: #101010;
    text-align: center;
  }
}
.van-cell {
  padding: 0;
}
.van-action-bar {
  padding: 0 10px 0 10px;
}
.container_item {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  //padding: 15px 10px 15px 10px;
  background: #ffffff;
  border-radius: 6px;
  &_main {
    display: flex;
    flex-direction: row;
    flex: 1;
    &_pic {
      height: 60px;
    }
    &_pic img {
      height: 60px;
    }
    &_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      &_title {
        font-size: 16px;
        color: #212121;
        font-weight: bold;
      }
      &_price {
        margin-top: 10px;
        color: #ff9100;
        font-size: 20px;
        &_tag {
          font-size: 12px;
          color: #9e9e9e;
          margin-left: 10px;
          line-height: 0;
        }
      }
    }
    &_score {
      margin-top: 2px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &_desc {
        color: #ff9100;
        font-size: 10px;
      }
    }
  }
}
.van-image {
  margin: 0 5px 0 5px;
  vertical-align: middle;
}
.container_bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding: 10px 10px;
  border-radius: 6px;
  height: 44px;
}
.van-button--large {
  height: 44px;
}
</style>
