<!--收货地址界面-->
<template>
  <div class="container">
    <div class="container_box">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                class="list"
            >
              <van-swipe-cell v-for="(item,index) in list" :key="index">
                  <div class="container_item">
                      <div class="container_item_titleline">
                        <div class="container_item_titleline_title">{{item.allAddress}}</div>
                          <div class="container_item_titleline_icon" @click="editAddress(item.id)"><img src="../../assets/images/center/addredit.png" alt=""></div>
                      </div>
                      <div class="container_item_addressline">{{item.address}}</div>
                      <div class="container_item_endline"><span class="container_item_endline_name">{{item.name}}</span> <span class="container_item_endline_phone">{{item.phonenumber}}</span></div>
                  </div>
                <template #right>
                  <van-button square text="删除" type="danger" class="delete-button" @click="deleteAddr(item.id)"/>
                </template>
              </van-swipe-cell>

            </van-list>
            </van-pull-refresh>
    </div>

    <div class="container_bottom">
      <van-button
        type="warning"
        size="large"
        color="#FFD11A"
        @click="saveData"
      >+新增收货地址</van-button>
    </div>
  </div>
</template>

<script setup name="ReceiveAddress">
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
import {useRouter} from "vue-router";
import { getReceiveAddress,deleteAddressById } from "../../api/shop";


const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
// const allchecked = ref([]);
// const checkboxGroup = ref(null);
const router = useRouter();
const onRefresh = () => {
  refreshing.value = true;
  onLoad();
};
const saveData = ()=>{
  router.push({path: "editAddress",query:{
    edit: 0
  }})
};
const editAddress = (id)=>{
    router.push({path: "editAddress",query:{
    edit: 1,
    id: id
  }})
}

const onLoad = async () => {
  loading.value = true
  refreshing.value = true;
  let result = await getReceiveAddress();
  console.log(result)
  if(result.code===200){
    list.value = result.rows
    loading.value = false
    refreshing.value = false;
    finished.value = true
  }else{
    loading.value = false
    refreshing.value = false;
    finished.value = true
  }
};

const deleteAddr = async (id)=>{
  let result = await deleteAddressById(id);
  if(result.code===200){
    Toast(result.msg)
    onLoad()
  }
}
</script>

<style lang="scss" scoped>
.container{
  position: relative;
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
    &_box{
      padding: 10px 10px;

    }
    &_bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 10px;
  }
  &_item{
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-bottom: 10px;
    padding: 15px 10px;
    border-radius: 6px;
        &_titleline{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &_title{
          font-size: 12px;
          color: #757575;
        }
        &_icon{

        }
         &_icon img{
           height: 14px;
        }
      }
      &_addressline{
        margin-top: 8px;
        color: #000000;
        font-size: 14px;
        font-weight: bold;
      }
      &_endline{
        margin-top: 12px;
        font-size: 12px;
        color: #757575;
        &_name{
          margin-right: 20px;
        }
      }
}
}
.delete-button {
    height: 100%;
  }
 .van-button--large{
    height: 44px;
}
</style>
