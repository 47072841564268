<template>
  <div class="container">
    <div class="container_month" v-if="id==='1'">
      <div class="container_month_top">
        <div class="container_month_top_left">活动周期</div>
        <div class="container_month_top_right">&nbsp;&nbsp;(多选)&nbsp;&nbsp;</div>
      </div>
      <van-checkbox-group v-model="monthChecked">
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="1">1号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="2">2号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="3">3号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="4">4号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="5">5号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="6">6号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="7">7号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="8">8号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="9">9号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="10">10号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="11">11号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="12">12号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="13">13号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="14">14号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="15">15号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="16">16号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="17">17号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="18">18号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="19">19号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="20">20号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="21">21号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="22">22号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="23">23号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="24">24号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="25">25号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="26">26号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="27">27号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="28">28号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="29">29号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="30">30号</van-checkbox>
        <div class="container_month_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="31">31号</van-checkbox>
      </van-checkbox-group>
    </div>
    <div class="container_week" v-if="id==='2'">
      <div class="container_week_top">
        <div class="container_week_top_left">活动周期</div>
        <div class="container_week_top_right">&nbsp;&nbsp;(多选)&nbsp;&nbsp;</div>
      </div>
      <van-checkbox-group v-model="weekChecked">
        <div class="container_week_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="1">周一</van-checkbox>
        <div class="container_week_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="2">周二</van-checkbox>
        <div class="container_week_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="3">周三</van-checkbox>
        <div class="container_week_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="4">周四</van-checkbox>
        <div class="container_week_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="5">周五</van-checkbox>
        <div class="container_week_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="6">周六</van-checkbox>
        <div class="container_week_line"></div>
        <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" name="7">周日</van-checkbox>

      </van-checkbox-group>
    </div>
    <div class="container_tag" v-if="id==='1'">
      <p>若选择29号，但当月不存在，则不会创建该活动时间。</p>
      <br>
      <p>若选择30号，但当月不存在，则不会创建该活动时间。</p>
      <br>
      <p>若选择31号，但当月不存在，则不会创建该活动时间。</p>
    </div>
  </div>

  <!--  按钮  -->
  <div class="container_bottom">
    <div class="container_bottom_visit" @click="toBack">
      取消
    </div>
    <van-button @click="toSignup">确定</van-button>
  </div>
</template>

<script setup>
import {ref,defineProps,defineEmits } from "vue";

const emit = defineEmits(['toSignup'])
const props = defineProps({
  id: String
})
const monthChecked = ref(['1', '2']);

const weekChecked = ref(['1', '2']);
const toBack=()=>{
  emit('goBack', false)
}
const toSignup=()=>{
  if(props.id==='1'){
    emit('toSignup',monthChecked.value)
  }
  if(props.id==='2'){
    emit('toSignup',weekChecked.value)
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  height: calc(100vh - 120px)!important;
  overflow-y: scroll;
  &_month {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px 18px 10px;

    &_top {
      display: flex;
      text-align: left;
      line-height: 18px;
      width: 335px;

      &_left {
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }

      &_right {
        color: rgba(158, 158, 158, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_line {
      margin-top: 18px;
      border: 1px solid #F5F5F5;
      width: 335px;
    }

    .van-checkbox-group {
      .van-checkbox {
        margin-top: 18px;
      }
    }
  }
  &_week {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px 18px 10px;

    &_top {
      display: flex;
      text-align: left;
      line-height: 18px;
      width: 335px;

      &_left {
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }

      &_right {
        color: rgba(158, 158, 158, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_line {
      margin-top: 18px;
      border: 1px solid #F5F5F5;
      width: 335px;
    }

    .van-checkbox-group {
      .van-checkbox {
        margin-top: 18px;
      }
    }
  }
  &_tag{
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 14px;
    color: rgba(97, 97, 97, 100);
    font-size: 14px;
    text-align: left;
    font-family: PingFangSC-regular;
  }
}

.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;
  display: flex;

  &_visit {
    margin-top: 22px;
    margin-bottom: 22px;
    margin-left: 56px;
    color: rgba(244, 190, 72, 100);
    font-size: 16px;
    text-align: left;
    font-weight: bolder;
    font-family: PingFangSC-medium;
  }

  .van-button {
    margin-left: 56px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 220px;
    height: 44px;
    border-radius: 6px;
    background-color: #FFBF1E;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
  }
}
</style>
