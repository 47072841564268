import request from "@/utils/request";

export function getLengthUserRange(params){
    return request({
        url: '/appreq/activityLength/getLengthUserRange',
        method: 'post',
        params
    })
}
export function getVipCard(params){
    return request({
        url: '/appreq/vipCard/getVipCard',
        method: 'get',
        params
    })
}
