<template>
    <div class="container">
     <van-form @submit="onSubmit">
      <van-cell-group inset>
        <template v-for="item in list" :key="item">
            <div v-if="item.tag==='el-input'">
              <van-field
                v-model="item.defaultValue"
                :name="item.vModel"
                :label="item.label"
                :placeholder="item.placeholder"
              />
            </div>
            <div v-else-if="item.tag==='el-radio-group'" >
              <van-field :name="item.vModel" :label="item.label">
              <template #input>
                <van-radio-group v-model="item.defaultValue" direction="horizontal">
                 <van-radio :name="option.value" v-for="option in item.options" :key="option">{{option.label}}</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            </div>

             <div v-else-if="item.tag==='el-checkbox-group'" >
              <van-field :name="item.vModel" :label="item.label">
              <template #input>
                <van-checkbox-group v-model="item.defaultValue" direction="horizontal">
                  <van-checkbox style="margin-bottom:10px" :name="option.value" v-for="option in item.options" :key="option" shape="square">{{option.label}}</van-checkbox>
                 
                </van-checkbox-group>
              </template>
            </van-field>
            </div>

            <div v-else-if="item.tag==='el-time-picker'">
              <van-field
                v-model="item.defaultValue"
                is-link
                readonly
                :name="item.vModel"
                :label="item.label"
                :placeholder="item.placeholder"
                @click="showPicker = true"

              />
              <van-popup v-model:show="showPicker" position="bottom">
                <van-datetime-picker
                  type="year-month"
                  @confirm="res=>onConfirm(item,res)"
                  @cancel="showPicker = false"
                  :formatter="formatter"
                 
                />
              </van-popup>
            </div>

            <div v-else-if="item.tag==='el-rate'">
              <van-field :name="item.vModel"  :label="item.label">
                <template #input>
                  <van-rate v-model="item.defaultValue" />
                </template>
              </van-field>
            </div>
             <div v-else-if="item.tag==='el-slider'">
              <van-field :name="item.vModel"  :label="item.label">
                <template #input>
                  <van-slider v-model="item.defaultValue" @change="res=>onChange(item,res)" />
                </template>
              </van-field>
            </div>


        </template>
      </van-cell-group>
      <div  class="container_bottombtn">
        <van-button round block type="primary" native-type="submit" @click="submitForm">
          提交
        </van-button>
      </div>
     </van-form>
   </div>
</template>
<script setup name="Free">
import { ref } from "@vue/reactivity";
import { getformById,addTbform} from "@/api/tbform"
import { useRoute } from "vue-router";
import { Toast } from 'vant';

const showPicker = ref(false);
const onConfirm = (item,value) => {
  console.log("value==",item,value)
  const date = new Date(value)
  const v = formatteTime(date)
  item.defaultValue = v
  showPicker.value = false;
};

const route = useRoute();
const list = ref([])
const getform = async ()=>{
  let result = await getformById(route.params.id)
  console.log("result==",result)
  if(result.code===200){
    list.value = JSON.parse(result.data.formSetting) 
  }
}

getform();


const formatteTime = (date)=>{
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? '0' + m : m
  var d = date.getDate()
  d = d < 10 ? '0' + d : d
  var h = date.getHours()
  h = h < 10 ? '0' + h : h
  var minute = date.getMinutes()
  minute = minute < 10 ? '0' + minute : minute
  var second = date.getSeconds()
  second = second < 10 ? '0' + second : second
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
}

const formatter = (type, val)=>{
   if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
}
const onChange = (item,value)=>{
  item.defaultValue = value
}
const submitForm = async()=>{
  const data = {fid: parseInt(route.params.id),formdata: JSON.stringify(list.value)}
  let result = await addTbform(data);
  if(result.code == 200){
    Toast(result.msg)
  }
}

const onSubmit = ()=>{
  console.log("list===",list.value)
}

</script>
<style lang="scss" scoped>
.container{
  width: 100%;
  position: relative;
  height: 100vh;
  padding-top: 10px;
  &_bottombtn{
    width: 100%;
    margin: 0 auto; 
    position: fixed; bottom: 0;
  }
}
.ghost {
  border: solid 1px rgb(19, 41, 239);
}
.chosenClass {
  background-color: #f1f1f1;
}
</style>