<template>

  <van-tabs
      v-model:active="active"
      background="#EDEDED"
      title-active-color="#212121"
      title-inactive-color="#757575"
      color="#212121"
      sticky
      @click-tab="onClickTab"
      animated
  >
    <van-tab :title="tab" v-for="tab in tablist" :key="tab">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" >
        <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="finishedText"
            v-if="!isEmpty"
            @load="onLoad"
            class="container"
        >
          <div class="container_item"  v-for="(item, i) in list" :key="i">
            <div class="container_item_top" @click="toClaimDetails(item.id)">
              <img :src="item.picUrl?item.picUrl:picUrl" alt="">
              <div class="container_item_top_right">
                <div class="container_item_top_right_content">
                  {{ item?.name }}
                </div>
                <div class="container_item_top_right_number">
                  <div class="container_item_top_right_number_left">
                    {{item?.money=='0.00'?'免费活动':'付费活动'}}
                  </div>
                  <div class="container_item_top_right_number_right">
                    可{{ item?.scoreType == '1' ? '获得' : '消耗' }}{{ item?.score }}积分
                  </div>
                </div>
                <div class="container_item_top_right_time">
                  活动时间：{{item?.starttime?.substring(0, 11)}} 至 {{item?.endtime?.substring(0, 11)}}
                </div>
              </div>
            </div>
            <div class="container_item_address">
              <div class="container_item_address_content">
                {{ item?.addressDetail?.length>25?(item?.addressDetail?.slice(0,25)+'...'):item?.addressDetail }}
              </div>
            </div>
            <div class="container_item_user">
              <img :src="item?.pubUser?.avatar?(item?.pubUser?.avatar):avatarUrl" alt="">
              <div class="container_item_user_put">
                {{ item?.pubUser?.username }}发布于{{item?.createTime?.substring(0, 10).replaceAll('-','.')}}
              </div>
              <div v-if="active===0" class="container_item_user_btn" @click="confirmBtn(item.id)">
                认领
              </div>
              <div v-else class="container_item_user_time">
                认领时间：{{item?.signTime?.substring(0, 10).replaceAll('-','.')}}
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <van-empty
          description="暂无信息"
          v-if="isEmpty"
      />
    </van-tab>

  </van-tabs>

</template>

<script setup>
import {ref} from "vue";
import {Dialog} from "vant";
import {useRouter} from "vue-router";
import {reactive} from "@vue/reactivity";
import {claim, getActivityList, getSignupList} from "@/api/activity";
const tablist = reactive(["长期活动","已认领"])

const router = useRouter();
const avatarUrl = ref('../../../assets/images/activity/avatar.png');
const picUrl = ref('../../../assets/images/activity/activity.png');
const  toClaimDetails=(id)=> {
  router.push({
    path: "/claimDetails",
    query: {
      id,
      active:active.value
    },
  });
}
const confirmBtn= (val)=>{
  Dialog.confirm({
    title: '认领活动',
    message:
        '为您的队伍认领当前长期活动',
  })
      .then(async() => {
        let response =await claim({aid:val});
        console.log(response)
      })
      .catch(() => {
        // on cancel
      });
}
const isEmpty = ref(false)
//分页
const query=ref({})
query.value= {pageNum: 1,pageSize: 10}
const finishedText = ref('');
const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const onRefresh = () => {
  query.value.pageNum = 1
  // 清空列表数据
  list.value = []
  finished.value = true
  finishedText.value = ''
  refreshing.value = true;
  onLoad()
};
const onLoad = () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  if(active.value===0){
    activityList()
  }else {
    signupList()
  }

};

const signupList = async ()=>{

  let response = await getSignupList(query.value,{});
  loading.value = false
  refreshing.value = false
  console.log(response.rows)
  if (response.rows.length > 0) {
    for (const e of response.rows) {
      list.value.push(e.activity)
    }
    query.value.pageNum++
    finished.value = false
    list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
  } else {
    // 数据全部加载完成
    finished.value = true
  }
  isEmpty.value = list.value.length === 0;
}

const activityList = async ()=>{

  let response = await getActivityList(query.value,{longType:"1",isMain:"1",checkStatus:"1"});
  loading.value = false
  refreshing.value = false
  console.log(response.rows)
  if (response.rows.length > 0) {

    list.value = list.value.concat(response.rows)
    query.value.pageNum++
    finished.value = false
    list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
  } else {
    // 数据全部加载完成
    finished.value = true
  }
  isEmpty.value = list.value.length === 0;
}
const active = ref(0);
const onClickTab = () => {
  onRefresh()
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 90px)!important;
  overflow-y: scroll;
  padding: 15px 10px 15px 10px;

  &_item {
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    padding: 10px 10px 15px 10px;
    margin-bottom: 15px;
    &_top {
      display: flex;
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
      }

      &_right {
        width: 245px;
        display: flex;
        flex-direction: column;
        padding: 0 10px 0 10px;
        &_content {
          text-align: left;
          color: #212121;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 12px;
        }
        &_number {
          width: 230px;
          background-color: rgba(255, 145, 0, 0.05); /* 不支持线性的时候显示 */
          background-image: linear-gradient(to right, rgba(255, 145, 0, 0.05), #ffffff);
          display: flex;
          align-items: center;
          border-radius: 2px;
          line-height: 24px;
          padding: 4px 5px 4px 5px;
          &_left {
            line-height: 16px;
            color: rgba(244, 190, 72, 100);
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-regular;
            flex: 1;
          }
          &_right {
            margin-left: 15px;
            line-height: 16px;
            color: rgba(33, 33, 33, 100);
            font-size: 12px;
            text-align: right;
            font-family: PingFangSC-medium;
          }
        }
        &_time {
          margin-top: 10px;
          color: rgba(33, 33, 33, 100);
          font-size: 10px;
          text-align: left;
          font-family: PingFangSC-regular;
        }
      }
    }

    &_address {
      margin-top: 20px;
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
      background-color: rgba(250, 250, 250, 100);

      &_content {
        text-align: left;
        margin-left: 10px;
        color: rgba(189, 189, 189, 100);
        font-size: 12px;
        font-weight: 550;
      }
    }

    &_user {
      line-height: 25px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }

      &_put {
        color: rgba(185, 190, 204, 100);
        font-size: 12px;
        text-align: left;
        margin-left: 8px;
        flex: 1;
      }

      &_btn {
        height: 23px;
        width: 50px;
        border-radius: 18px;
        background-color: rgba(255, 145, 0, 0);
        color: rgba(255, 145, 0, 100);
        font-size: 14px;
        text-align: center;
        font-family: Microsoft Yahei;
        border: 1px solid rgba(255, 145, 0, 100);
      }
      &_time{
        color: rgba(33, 33, 33, 100);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
  }
}
</style>
