import request from "@/utils/request";

export function getMessageList(params, data){
    return request({
        url: '/appreq/message/list',
        method: 'get',
        headers: {
            isToken: true
        },
        data,
        params
    })
}

export function getMessageDetail(id){
    return request({
        url: '/appreq/message/'+id,
        headers: {
            isToken: true
        },
        method: 'get',
    })
}
