<!--我的发布-->
<template>
  <div class="container">
    <van-tabs v-model:active="active" background="#EDEDED" @click-tab="onClickTab">
      <van-tab :title="item" v-for="item in tablist" :key="item">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                v-model:loading="loading"
                :finished="finished"
                :finished-text="finishedText"
                v-if="!isEmpty"
                @load="onLoad"
                class="list"
            >
              <div class="container_acticity_item" @click="toMypubDetails(item.id)" v-for="(item, i) in list" :key="i">
                  <div class="container_acticity_item_left">
                    <img :src="item.picUrl?item.picUrl:picUrl" alt="">
                  </div>
                  <div class="container_acticity_item_right">
                      <div class="container_acticity_item_right_title">
                          <div class="container_acticity_item_right_title_span">
                            {{ item?.name }}
                          </div>
                          <div class="container_acticity_item_right_title_type">
                            {{ item?.activityType?.name }}
                          </div>
                      </div>
                      <div class="container_acticity_item_right_info">
                        <div :class="[item.checkStatus==='0'?'container_acticity_item_right_info_state1':(item.checkStatus==='2'?'container_acticity_item_right_info_state':(item.status==='0'?'container_acticity_item_right_info_state3':(item.status==='1'?'container_acticity_item_right_info_state4':(item.status==='2'?'container_acticity_item_right_info_state5':'container_acticity_item_right_info_state6'))))]">
                          {{ item.checkStatus==="0"?"待审核":(item.checkStatus==="2"?"已驳回":(item.status==='0'?'已结束':(item.status==='1'?'进行中':(item.status==='2'?'报名中':'未开始'))))  }}
                        </div>
                        <div class="container_acticity_item_right_info_date">发布于{{item.createTime.substring(0, 10).replaceAll('-','.')}}</div>
                      </div>
                  </div>
              </div>

            </van-list>
            </van-pull-refresh>
        <van-empty
            description="暂无信息"
            v-if="isEmpty"
        />
      </van-tab>

    </van-tabs>
  </div>
</template>
<script setup name="Mypub">
import { reactive, ref } from "@vue/reactivity";
import {useRouter} from "vue-router";
import {getMyPubList} from "@/api/activity";
const picUrl = ref('../../assets/images/activity/activity.png');
const isEmpty = ref(false)
const tablist = reactive(["全部活动","待审核","已驳回","已完成","进行中",'报名中','未开始'])
const router = useRouter();
const  toMypubDetails=(id)=> {
  router.push({
      path: "/mypubDetails",
    query: {
      id
    },
  });
}
const active = ref(0);
const onClickTab = () => {
  onRefresh()
};
const query=ref({})
query.value= {pageNum: 1,pageSize: 10,oper:"1"}
const finishedText = ref('');
const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);

const onRefresh = () => {
  query.value.pageNum = 1
  // 清空列表数据
  list.value = []
  finished.value = true
  finishedText.value = ''
  refreshing.value = true;
  onLoad();
};
const onLoad = () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  if(active.value===0){
    query.value.statusQ="0"
  }else if(active.value===1){
    query.value.statusQ="1"
  }else if(active.value===2){
    query.value.statusQ="2"
  }else if(active.value===3){
    query.value.statusQ="3"
  }else if(active.value===4){
    query.value.statusQ="4"
  }else if(active.value===5){
    query.value.statusQ="5"
  }else {
    query.value.statusQ="6"
  }
  activityList()
};
const activityList = async ()=>{

  let response = await getMyPubList(query.value);
  loading.value = false
  refreshing.value = false
  console.log(response.rows)
  if (response.rows.length > 0) {

    list.value = list.value.concat(response.rows)
    query.value.pageNum++
    finished.value = false
    list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
  } else {
    // 数据全部加载完成
    finished.value = true
  }
  isEmpty.value = list.value.length === 0;
}
</script>
<style lang="scss" scoped>
.container{
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
  &_acticity_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 10px;
    padding: 10px 10px;
    background: #fff;
    border-radius: 6px;
    &_left{
      margin-right: 10px;
      height: 80px;
      img{
        height: 80px;
        width: 80px;
      }
    }
    &_right{
      height: 70px;
      width: 255px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_title{
        display: flex;
        flex-direction: row;
        line-height: 12px;
        &_span{
          flex: 1;
          font-size: 14px;
          color: #212121;
        }
        &_type{
          margin-left: 20px;
          font-size: 12px;
          color: #F4BE48;
          background-color: rgba(244, 190, 72, 0.1);

        }
      }
      &_info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &_state1{
          border: 1px solid rgba(244, 190, 72, 100);
          color: rgba(244, 190, 72, 100);
          font-size: 10px;
          border-radius: 2px;
          padding: 2px 2px;
        }
        &_state2{
          border: 1px solid rgba(255, 61, 0, 100);;
          color: rgba(255, 61, 0, 100);;
          font-size: 10px;
          border-radius: 2px;
          padding: 2px 2px;
        }
        &_state3{
          border: 1px solid rgba(33, 33, 33, 100);
          color: rgba(33, 33, 33, 100);
          font-size: 10px;
          border-radius: 2px;
          padding: 2px 2px;
        }
        &_state4{
          border: 1px solid rgba(0, 230, 118, 100);
          color: #00E676;
          font-size: 10px;
          border-radius: 2px;
          padding: 2px 2px;
        }
        &_state5{
          border: 1px solid rgba(98, 212, 204, 100);
          color: rgba(98, 212, 204, 100);
          font-size: 10px;
          border-radius: 2px;
          padding: 2px 2px;
        }
        &_state6{
          border: 1px solid rgba(98, 212, 204, 100);
          color: #ff9959;
          font-size: 10px;
          border-radius: 2px;
          padding: 2px 2px;
        }
        &_date{
          font-size: 12px;
          color: #B9BECC;
        }
      }
    }
  }
}

</style>

