<template>
            <div class="container_item">
            <div class="container_item_pic">
                <van-image :src="item.images[0]" alt="" />
            </div>
            <div class="container_item_info">
                <div class="container_item_info_title">{{item.name}}</div>
                <div class="container_item_info_detail" v-if="item.payType==='1'">
                    <div class="container_item_info_detail_price" v-if="item.isRebate==='0'"><span>¥{{item.discountPrice}}</span> <span class="container_item_info_detail_tag">¥{{item.originalPrice}}</span></div>
                    <div class="container_item_info_detail_price" v-else-if="item.isRebate==='1'"><span>¥{{item.originalPrice}}</span></div>
                    <div class="container_item_info_detail_btn"><van-button round type="warning" size="small" @click="toPay">立即抢购</van-button></div>
                </div>
                 <div class="container_item_info_detail" v-else-if="item.payType==='2'">
                    <div class="container_item_info_detail_price" ><span>积分{{item.score}}</span></div>
                    <div class="container_item_info_detail_btn"><van-button round type="warning" size="small" @click="toPay">立即抢购</van-button></div>
                </div>
                <div class="container_item_info_detail" v-else-if="item.payType==='3'">
                    <div class="container_item_info_detail_price" v-if="item.isRebate==='0'"><span>¥{{item.discountPrice}} 积分{{item.score}}</span> <span class="container_item_info_detail_tag">¥{{item.originalPrice}}</span></div>
                    <div class="container_item_info_detail_price" v-else-if="item.isRebate==='1'"><span>¥{{item.originalPrice}} 积分{{item.score}}</span></div>
                    <div class="container_item_info_detail_btn"><van-button round type="warning" size="small" @click="toPay">立即抢购</van-button></div>
                </div>
            </div>
        </div>
</template>
<script setup name="GoodsItem">
import { defineProps, ref } from 'vue';
import { useRouter } from 'vue-router';
import { addCart } from '../api/shop';
const payList = ref([]);
const router = useRouter();
 const props = defineProps({item: Object})
 const addToCart = async () => {
  let cart = {
    goodsId: props.item.id,
    num: props.item.num ?? 1
  }
  let result = await addCart(cart);
  if (result.code === 200) {
    console.log(result)
  }
  return result.data
}
const toPay = async ()=>{
  let cartGoods = await addToCart();
  cartGoods.check = true
  console.log("cartGoods===",cartGoods)
  payList.value.push(cartGoods)
  let price = 0;
  let score = 0;
  console.log("payList.value", payList.value)
  for (let item of payList.value) {
      console.log("item.num===",item)
    if(item?.vlShopGoods?.payType==="1"){
      price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
    }else if(item?.vlShopGoods?.payType==="2"){
      score += item?.vlShopGoods?.score * item.num
    } else{
      price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
      score += item?.vlShopGoods?.score * item.num
    }
  }
  if(payList.value!=null && payList.value.length>0){
      router.push({ name: "ShopPay", params: { list: JSON.stringify(payList.value),totalPrice: price,totalScore: score } })
    }
}
</script>

<style lang="scss" scoped>
    .container_item{
        margin-top: 10px;
        background: #FFFFFF;
        padding: 10px 15px 10px 5px;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        &_pic{
            margin-right: 5px;
        }
        &_pic img{
            width: 80px;
            height: 80px;
        }
        &_info{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            &_title{
                font-size: 14px;
                color: #212121;
            }
            &_detail{
                
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                &_price{
                    color: #FF9100;
                    font-size: 16px;
                }
                &_tag{
                    color: #BDBDBD;
                    font-size: 10px;
                    margin-left: 11px;
                    text-decoration: line-through;
                }
            }

        }
    }
.van-button--warning{
    background: #FF9100;
}
.van-image{
    width: 80px;
    height: 80px;
}
</style>

