<template>
<!--  <van-nav-bar
      title="绑定手机"
      class="bgcolor"
  ></van-nav-bar>-->
  <div class="container">
    <div class="container_userinput">
      <div class="container_userinput_title">手机号码</div>
      <div class="container_userinput_input"><input
          placeholder="请输入手机号"
          name="phone"
          v-model="phone"
      /></div>
    </div>
    <div class="container_verifyCode">
      <div class="container_verifyCode_leftblock">
        <div class="container_verifyCode_title">验证码</div>
        <div class="container_verifyCode_input"><input
            placeholder="请输入验证码"
            name="code"
            v-model="code"
        /></div>
      </div>

      <div class="container_verifyCode_btn">
        <van-button
            plain
            type="warning"
            size="mini"
            @click="getCode"
        >{{loginCodeText}}</van-button>
      </div>
    </div>
    <div class="container_loginbtn">
      <van-button
          type="warning"
          size="large"
          @click="submit"
      >确认绑定</van-button>
    </div>
  </div>
</template>
<script setup name="BindPhone">
import {useRoute, useRouter} from "vue-router";

import {Toast} from "vant";
import {ref} from "@vue/reactivity";
import { getSmsCode,bindingPhone} from '@/api/user';
import {setOpenid, setToken} from "../../utils/auth";
const router = useRouter();
const route = useRoute();
const phone = ref('');
const code = ref('');
const user = JSON.parse(route.params.user);
const state = route.params.state;
const loginCodeText = ref('获取验证码');
const getCode = async () => {
  if(phone.value === ''){
    Toast('请输入手机号');
    return;
  }
  if(loginCodeText.value !== '获取验证码'){
    Toast('验证码已发送，请注意查收');
    return;
  }
  var timer;
  const params = { "phone": phone.value }
  let result = await getSmsCode(params);
  if(result.code === 200){
    Toast(result.data)
    console.log(result)
    var ss = 59
    timer = setInterval(function() {
      ss--
      loginCodeText.value = '验证码(' + ss + 's)'
      if (ss <= 0) {
        clearInterval(timer)
        loginCodeText.value = '获取验证码'
      }
    }, 1000)
  }else {
    clearInterval(timer);
    loginCodeText.value = '获取验证码';
  }
}


const submit = async () =>{
  const params = {
    "phone": phone.value,
    "code":code.value,
    "openid":user.openid,
    "avatar":user.avatar,
    "nickname":user.nickname
  }
  let result = await bindingPhone(params);
  Toast(result.data)
  if(result!=null && result.code==200){
    setToken(result.data.token)
    setOpenid(result.data.user.openid)
    if(state === 'wxlogin#/wxLogin'){
      router.push({ name: 'Home' })
    }else {
      router.go(-1);
    }
  }

  console.log(result)
}






</script>
<style lang="scss" scoped>
.bgcolor {
  background: #fff;
}
.container {
  position: relative;
  display: flex;
  height: 100%;
  background: #fff;
  flex-direction: column;
  padding: 30px 30px 30px 30px;
  overflow: hidden;
  &_login_info {
    display: flex;
    flex-direction: column;
    justify-content: left;
    &_title {
      font-size: 24px;
      color: #212121;
      font-weight: bold;
    }
    &_desc {
      margin-top: 10px;
      font-size: 10px;
      color: #b9becc;
    }
  }
  &_userinput {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    height: 80px;
    background: #fafafa;
    border-radius: 6px;
    &_title {
      margin: 15px 0 0 15px;
      color: #212121;
      font-size: 14px;
      font-weight: 600;
    }
    &_input {
      line-height: 20px;
      margin: 15px 0 0 15px;
      width: 100%;
      display: flex;
      align-items: center;
      input {
        border: none;
        background: none;
        color: #ff9100;
        font-size: 14px;
      }
    }
  }
  &_verifyCode {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 80px;
    background: #fafafa;
    border-radius: 6px;
    justify-content: space-between;
    &_leftblock {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &_title {
      color: #212121;
      font-size: 14px;
      margin: 15px 0 0 15px;
      font-weight: 600;
    }
    &_input {
      line-height: 20px;
      width: 30%;
      margin: 15px 0 15px 15px;
      display: flex;
      align-items: center;
      input {
        border: none;
        background: none;
        color: #ff9100;
        font-size: 14px;
      }
    }
    &_btn {
      line-height: 1px;
      justify-self: center;
      align-self: center;
      margin-right: 15px;
    }
  }
  &_loginbtn {
    margin-top: 30px;
    width: 100%;
  }
  &_loginType {
    margin-top: 40px;
    font-size: 10px;
    color: #b9becc;
    text-align: center;
    align-items: center;
  }
  &_typeBtns {
    padding-left: 87px;
    padding-right: 87px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: space-around;
    img {
      width: 40px;
      height: 40px;
    }
  }
  &_bottom {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 0;
    margin-bottom: 120px;
    &_check {
      margin-right: 6px;
    }
    &_check img {
      width: 14px;
    }
    &_info {
      color: #9da6c3;
      font-size: 12px;
    }
  }
}
.van-button--large {
  height: 66px;
  background: #ff9100;
}
.van-button--mini {
  height: 32px;
  color: #ff9100;
  border-color: #ff9100;
  border-radius: 4px;
  font-size: 12px;
}
</style>
