import request from '@/utils/request'

export function getSignature(data){
    return request({
        url: '/appreq/wxShare/getSignature',
        method: 'post',
        data
    })
}
export function getSignatureCard(data){
    return request({
        url: '/appreq/cardBag/getSignatureCard',
        method: 'post',
        data
    })
}

export function wxPay(data){
    return request({
        url: '/appreq/shopOrder/createOrder',
        method: 'post',
        data
    })
}
