import request from '@/utils/request'



//根据id查询表单配置
export function getformById(id){
    return request({
        url: '/appreq/tbform/getTbformById/'+id,
        method: 'get',
    })
}

export function addTbform(data){
    return request({
        url: '/appreq/tbform/addTbData',
        method: 'post',
        data,
    })
}

