<!--首页活动列表-->
<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-if="!isEmpty"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list"
    >
      <div class="item" @click="toDetail(item.id)" v-for="(item, i) in list" :key="i">
        <div class="top">
          <img :src="item.picUrl?item.picUrl:picUrl" alt="">
          <div class="right">
            <div class="content">
              {{ item.name }}
            </div>
            <div class="number">
              <div class="number_left">
                <div class="sign">
                  已报名
                </div>
                <div class="num" v-if="item.alreaySignUpNum===0">
                  <div class="num_item" >0</div>
                </div>
                <div class="num" v-else>
                  <div class="num_item" v-for="(num, i) in (item.alreaySignUpNum.toString().split(''))" :key="i">{{ num }}</div>
                </div>
              </div>
              <div class="number_right">
                限{{ item.limitNum }}人
              </div>
            </div>
            <div class="time">
              <div class="time_left">
                报名截止时间：{{ item.signEndTime }}
              </div>
              <div v-if="item.longType=='0'" :class="[item.status==='0'?'time_right3':(item.status==='1'?'time_right2':(item.status==='2'?'time_right1':'time_right4'))]">
                {{ item.status==='0'?'已结束':(item.status==='1'?'进行中':(item.status==='2'?'报名中':'未开始')) }}
              </div>
            </div>
          </div>
        </div>
        <div class="address">
          <div class="content">
            {{ item?.addressDetail?.length>25?(item?.addressDetail?.slice(0,25)+'...'):item?.addressDetail }}
          </div>
        </div>
        <div class="user">
          <img :src="item.pubUser?item.pubUser.avatar:avatarUrl" alt="">
          <div class="put">
            {{ item.pubUser?item.pubUser.username:'' }}发布于{{item.createTime.substring(0, 10).replaceAll('-','.')}}
          </div>
          <div class="score">
            可{{item.scoreType==='1'?'获得':'消耗'}}{{item.score}}积分

          </div>
        </div>
      </div>

    </van-list>
  </van-pull-refresh>
  <van-empty
      description="暂无信息"
      v-if="isEmpty"
  />
</template>
<script setup>
  import {ref} from "vue";
  import {getActivityList} from "@/api/home";
  import {useRoute, useRouter} from "vue-router";
  const route = useRoute();
  const typId = route.query.typId;
  console.error('typId-->'+typId);
  const query=ref({})
  query.value= {pageNum: 1,pageSize: 10}
  const list = ref([]);
  const loading = ref(false);
  const refreshing = ref(false);
  const finished = ref(false);
  const isEmpty = ref(false);
  const finishedText = ref('');
  const router = useRouter();
  import { useStore } from 'vuex';
  const store = useStore();
  const onRefresh = () => {
    refreshing.value = true;
    query.value.pageNum = 1;
    onLoad()
  };
  const onLoad = async () => {
    if (refreshing.value) {
      list.value = [];
      refreshing.value = false;
    }
    let response = await getActivityList(query.value,{stationId:store.state.stationId,actTypeId:typId,checkStatus:"1",oper:"1"});
    loading.value = false
    refreshing.value = false
    console.log(response.rows)
    if (response.rows.length > 0) {

      list.value = list.value.concat(response.rows)
      query.value.pageNum++
      finished.value = false
      list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
    } else {
      // 数据全部加载完成
      finished.value = true
    }
    isEmpty.value = list.value.length === 0;
  };

  const  toDetail=(id)=> {
    router.push({
      path: "/activityDetails",
      query: {
        id
      },
    });
  }

</script>
<style lang="scss" scoped>
.list {
  height: calc(100vh - 90px) !important;
  overflow-y: scroll;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .item {
    height: 200px;
    background-color: #FFFFFF;
    border-radius: 6px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
      display: flex;
      height: 90px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-left: 10px;
        margin-top: 10px;
      }

      .right {
        margin-left: 10px;
        margin-right:10px;

        .content {
          color: #212121;
          font-size: 14px;
          font-weight: 600;
          margin-top: 14px;
          margin-bottom: 12px;
        }

        .number {
          width: 245px;
          height: 24px;
          background-color: rgba(255, 145, 0, 0.05); /* 不支持线性的时候显示 */
          background-image: linear-gradient(to right, rgba(255, 145, 0, 0.05), #ffffff);
          display: flex;
          align-items: center;

          .number_left {
            display: flex;
            align-items: center;
            flex: 1;

            .sign {
              height: 16px;
              line-height: 16px;
              font-size: 12px;
              color: #F4BE48;
              margin-left: 5px;
            }

            .num {
              display: flex;
              margin-left: 5px;

              .num_item {
                width: 16px;
                border: 1px solid rgba(255, 145, 0, 20);
                background-color: rgba(255, 255, 255, 100);
                line-height: 16px;
                border-radius: 2px;
                margin-right: 3px;
                height: 16px;
                font-size: 12px;
                font-weight: bolder;
                text-align: center;
                color: rgba(255, 145, 0, 100);
              }
            }
          }

          .number_right {
            height: 16px;
            line-height: 16px;
            font-size: 12px;
            color: rgba(244, 190, 72, 100);
          }
        }

        .time {
          display: flex;
          margin-top: 10px;

          .time_left {
            flex: 1;
            height: 16px;
            line-height: 16px;
            color: rgba(33, 33, 33, 100);
            font-size: 10px;
          }

          .time_right1 {
            height: 16px;
            line-height: 16px;
            border-radius: 2px;
            background-color: rgba(98, 212, 204, 100);
            font-size: 10px;
            text-align: center;
            color: rgba(255, 255, 255, 100);
          }
          .time_right2 {
            height: 16px;
            line-height: 16px;
            border-radius: 2px;
            background-color: rgba(0, 230, 118, 100);
            font-size: 10px;
            text-align: center;
            color: rgba(255, 255, 255, 100);
          }
          .time_right3 {
            height: 16px;
            line-height: 16px;
            border-radius: 2px;
            background-color: rgba(33, 33, 33, 100);
            font-size: 10px;
            text-align: center;
            color: rgba(255, 255, 255, 100);
          }
          .time_right4 {
            height: 16px;
            line-height: 16px;
            border-radius: 2px;
            background-color: #ff9959;
            font-size: 10px;
            text-align: center;
            color: rgba(255, 255, 255, 100);
          }
        }
      }
    }

    .address {
      margin-top: 20px;
      width: 335px;
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
      background-color: rgba(250, 250, 250, 100);

      .content {
        margin-left: 10px;
        color: rgba(189, 189, 189, 100);
        font-size: 12px;
        font-weight: 550;
      }
    }

    .user {
      width: 335px;
      height: 25px;
      margin-top: 20px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }

      .put {
        color: rgba(185, 190, 204, 100);
        font-size: 12px;
        text-align: left;
        margin-left: 8px;
        flex: 1;
      }

      .score {
        color: rgba(33, 33, 33, 100);
        font-size: 12px;
        font-weight: bolder;
      }
    }
  }
}
</style>
