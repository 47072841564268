<template>
    <div class="container">
       <!-- 详细 -->
       <div class="container_detail">
         <div class="container_detail_item">
           <div class="container_detail_item_name">
             收货人
           </div>
           <div class="container_detail_item_right">
             <div class="container_detail_item_right_des">
               <van-field
                   v-model="addr.name"
                   input-align="left"
                   placeholder="请输入"
               />
             </div>
           </div>
         </div>
         <div class="container_detail_line"></div>
         <div class="container_detail_item">
           <div class="container_detail_item_name">
             手机号码
           </div>
           <div class="container_detail_item_right">
             <div class="container_detail_item_right_des">
               <van-field
                   v-model="addr.phonenumber"
                   input-align="left"
                   placeholder="请输入"
               />
             </div>
           </div>
         </div>
         <div class="container_detail_line"></div>
         <div class="container_detail_item">
           <div class="container_detail_item_name">
             所在地区
           </div>
           <div class="container_detail_item_right">
             <div class="container_detail_item_right_des">
               <van-field
                   v-model="addr.addressName"
                   placeholder="请选择"
                   readonly
                   @click="areaShow = true"
                   input-align="left"
               />
             </div>
             <img src="../../assets/images/activity/right_edit.png" alt="">
           </div>
         </div>
         <div class="container_detail_line"></div>
         <div class="container_detail_item">
           <div class="container_detail_item_name">
             详细地址
           </div>
           <div class="container_detail_item_right">
             <div class="container_detail_item_right_des">
               <van-field
                   v-model="addr.address"
                   input-align="left"
                   placeholder="请输入"
               />
             </div>
           </div>
         </div>
         <div class="container_detail_line"></div>
         <div class="container_detail_item">
           <div class="container_detail_item_name">
             默认地址
           </div>
           <div class="container_detail_item_right">
             <div class="container_detail_item_right_des">
               <van-switch v-model="addr.isDefault" size="16px" active-color="#FF9100" inactive-color="#F5F5F5"/>
             </div>
           </div>
         </div>

       </div>
    </div>
    <!-- 底部按钮 -->
    <div class="container_bottom">
      <van-button @click="saveOrEdit">保存</van-button>
    </div>


  <!-- 弹出框 -->
  <van-popup
      v-model:show="areaShow"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-area
        title="所在地区"
        :area-list="areaList"
        value=""
        @cancel="areaShow = false"
        @confirm="addressConfirm"
    />
  </van-popup>


</template>
<script setup name="EditAddress">
import {reactive, ref, toRefs} from "vue";
import { useRoute,useRouter } from "vue-router";
import { areaList } from '@vant/area-data';
import { addAddress,updateAddress,getAddressById } from "../../api/shop"
import { Toast } from "vant";

const router = useRouter();
const route = useRoute();
let edit = route.query.edit;
const data = reactive({addr:{}})
const { addr } = toRefs(data)
const areaShow = ref(false);

const getAddress = async ()=>{
  let result = await getAddressById(route.query.id);
  if(result.code===200){
    addr.value = result.data
    if(addr.value.isDefault==="0"){
      addr.value.isDefault = true
    }else{
      addr.value.isDefault = false
    }
  }
}

if(route.query.edit==="1"){
  getAddress()
}
const addressConfirm = (val) => {
  if (val.length > 0) {
    if (val.length === 1) {
      addr.value.provinceId = val[0].code
      addr.value.addressName = val[0].name
    } else if (val.length === 2) {
      addr.value.provinceId = val[0].code
      addr.value.cityId = val[1].code
      addr.value.addressName = val[0].name + "-" + val[1].name
    } else {
      addr.value.provinceId = val[0].code
      addr.value.cityId = val[1].code
      addr.value.areaId = val[2].code
      addr.value.addressName = val[0].name + "-" + val[1].name + "-" + val[2].name
    }
    areaShow.value = false
  }
}

const saveOrEdit = async ()=>{
  console.log("edit===",edit)
  if(edit==='0'){
    //新增
    if(addr.value.isDefault===true){
      addr.value.isDefault = 0
    }else{
      addr.value.isDefault = 1
    }
    
    let result = await addAddress(addr.value);
    console.log(result)
    if(result.code===200){
      Toast(result.msg);
      router.back();
    }

  }else{
    //更新
    addr.value.id = route.query.id
     if(addr.value.isDefault===true){
      addr.value.isDefault = 0
    }else{
      addr.value.isDefault = 1
    }
    let result = await updateAddress(addr.value);
    console.log(result)
    if(result.code===200){
      Toast(result.msg);
      router.back();
    }
  }
}

</script>
<style lang="scss" scoped>
.van-background-default {
  background-color: rgba(245, 245, 245, 100);
  color: rgba(189, 189, 189, 100);
}

.van-background-checked {
  background-color: rgba(255, 145, 0, 100);
  color: rgba(255, 255, 255, 100);
}
.container {
  position: relative;
  height: calc(100vh - 130px) !important;
  overflow-y: scroll;
  padding: 0px 10px 10px 10px;

  &_detail {
     margin-top: 10px;
     line-height: 20px;
     border-radius: 6px;
     background-color: rgba(255, 255, 255, 100);
     color: rgba(16, 16, 16, 100);
     font-size: 14px;
     text-align: center;
     font-family: Arial;
     display: flex;
     flex-direction: column;
     padding: 18px 10px 18px 10px;

    &_item {
      display: flex;

      &_name {
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
        flex: 2;
      }

      &_right {
        flex: 7;
        height: 20px;
        display: flex;
        align-items: center;

        &_des {
          line-height: 18px;
          color: rgba(33, 33, 33, 100);
          font-size: 16px;
          text-align: center;
          font-family: PingFangSC-medium;
          font-weight: bolder;
          flex:1;

          .van-switch{
            float:right;
          }
        }

        img {
          width: 16px;
          height: 16px;
        }
      }


    }

    &_line {
      margin-top: 18px;
      margin-bottom: 18px;
      border: 1px solid #F5F5F5;
      width: 335px;
    }
   }


}
.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #FFBF1E;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}



</style>
