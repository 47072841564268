<template>
    <div class="container">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
         v-if="!isempty"
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          class="list"
      >
      <template v-for="item in list" :key="item">
          <GoodsItem :item="item"></GoodsItem>
      </template>
     

        <!-- <div class="container_item" v-for="item in list" :key="item">
            <div class="container_item_pic">
                <img src="../../assets/images/shop/g1.png" alt="">
            </div>
            <div class="container_item_info">
                <div class="container_item_info_title">露安适活力倍健低泡沐浴露200毫升
沐浴露200毫升</div>
                <div class="container_item_info_detail">
                    <div class="container_item_info_detail_price">¥68 <span class="container_item_info_detail_tag">¥98</span></div>
                    <div class="container_item_info_detail_btn"><van-button round type="warning" size="small">立即抢购</van-button></div>
                </div>
            </div>
        </div> -->

      </van-list>
      </van-pull-refresh>
       <van-empty
            description="暂无信息"
            v-if="isempty"
          />
    </div>
</template>

<script setup name="ShopHot">
import { reactive, ref, toRefs } from "@vue/reactivity";
import GoodsItem from "../../components/GoodsItem.vue";
import { getShopGoods } from "../../api/shop"; 
import { Toast } from "vant";
const list = ref([]);
const query = reactive({ queryParams: { pageSize: 10, pageNum: 1, flag: '1' } })
const { queryParams } = toRefs(query)
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const isempty = ref(false)
const onRefresh = () => {
  refreshing.value = true;
  onLoad();
};
const onLoad = async () => {
   if (refreshing.value) {
    list.value = [];
    refreshing.value = false;
  }
   let result = await getShopGoods(queryParams.value);
  if (result?.code === 200) {
    loading.value = false;
    list.value = result?.rows;
    finished.value = true;
    isempty.value = false;
    if (list.value.length < result?.total) {
      queryParams.value.pageNum++;
    }
  } else if (result?.code === 410) {
    loading.value = false;
    list.value = [];
    Toast("未查询到相关信息");
    finished.value = true;
    isempty.value = true;
  } else {
    loading.value = false;
    finished.value = true;
    isempty.value = true;
  }
};
</script>

<style lang="scss" scoped>
.container{
    height: calc(100vh - 100px)!important;
    overflow-y: scroll;

    padding: 5px 10px 15px 10px;
    // &_item{
    //     margin-top: 10px;
    //     background: #FFFFFF;
    //     padding: 10px 15px 10px 5px;
    //     border-radius: 6px;
    //     display: flex;
    //     flex-direction: row;
    //     &_pic{}
    //     &_pic img{
    //         height: 80px;
    //     }
    //     &_info{
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: space-between;
    //         &_title{
    //             font-size: 14px;
    //             color: #212121;
    //         }
    //         &_detail{
    //             display: flex;
    //             flex-direction: row;
    //             justify-content: space-between;
    //             align-items: center;
    //             &_price{
    //                 color: #FF9100;
    //                 font-size: 16px;
    //             }
    //             &_tag{
    //                 color: #BDBDBD;
    //                 font-size: 10px;
    //                 margin-left: 11px;
    //             }
    //         }

    //     }
    // }
}

</style>