<template>

  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad"
        class="container"
        v-if="!isEmpty"
    >
      <div class="container_item" v-for="(item, i) in list" :key="i">
        <div class="container_item_top" @click="toSignedDetails(item.activity.id)">
          <img :src="item?.activity?.picUrl?(item?.activity?.picUrl):picUrl" alt="">
          <div class="container_item_top_right">
            <div class="container_item_top_right_content">
              {{ item?.activity?.name }}
            </div>
            <div class="container_item_top_right_number">
              <div class="container_item_top_right_number_left">
                可{{item?.activity?.scoreType==='1'?'获得':'消耗'}}{{item?.activity?.score}}积分
              </div>
            </div>
            <div class="container_item_top_right_time">
              <div class="container_item_top_right_time_left">
                开始时间：{{item?.activity?.starttime.substring(0, 10).replaceAll('-','.')}}
              </div>
              <div :class="[item.activity.status==='0'?'container_item_top_right_time_right_two3':(item.activity.status==='1'?'container_item_top_right_time_right_two2':(item.activity.status==='2'?'container_item_top_right_time_right_two1':'container_item_top_right_time_right_two4'))]" >
                {{ item.activity.status==='0'?'已结束':(item.activity.status==='1'?'进行中':(item.activity.status==='2'?'报名中':'未开始')) }}
              </div>
            </div>
          </div>
        </div>
        <div class="container_item_address">
          <div class="container_item_address_content">
            {{ item?.activity?.addressDetail }}
          </div>
        </div>
        <div class="container_item_user">
          <img :src="item?.activity?.pubUser.avatar?(item?.activity?.pubUser?.avatar):avatarUrl" alt="">
          <div class="container_item_user_put">
            {{ item?.activity?.pubUser?.username }}发布于{{item?.createTime.substring(0, 10).replaceAll('-','.')}}
          </div>
          <van-button color="rgba(255, 145, 0, 100)" plain v-if="item?.activity?.status==='1'&&item?.activity?.signStatus==='0'" class="container_item_user_btn" @click="confirmBtn(item.activity.id)">签到</van-button>
          <van-button color="rgba(255, 145, 0, 100)" plain v-if="item?.activity?.status==='1'&&item?.activity?.signStatus==='1'&&item?.activity?.signOffStatus==='0'" class="container_item_user_btn" @click="confirmBtnOff(item.activity.id)">签退</van-button>
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
  <van-empty
      description="暂无信息"
      v-if="isEmpty"
  />
</template>

<script setup>
import {ref} from "vue";
import { Toast} from "vant";
import { useRouter } from "vue-router";
import {getSignupList, sign, signoff} from "@/api/activity";
const avatarUrl = ref('../../../assets/images/activity/avatar.png');
const picUrl = ref('../../../assets/images/activity/activity.png');
const router = useRouter();
const isEmpty = ref(false)
//分页
const query=ref({})
query.value= {pageNum: 1,pageSize: 10}

const confirmBtn=(val)=>{
  Toast.loading({
    message: '签到中...',
    forbidClick: true,
  })
  setTimeout(async ()=>{
    let response =await sign({aid:val});
    if(response!=null){
      router.push({
        path: "/onSiteCheckIn",
        query: {
          sid: response.data.id,
          type:"1"
        },
      });
    }

  },2000)
}
const confirmBtnOff=(val)=>{
  Toast.loading({
    message: '签退中...',
    forbidClick: true,
  })
  setTimeout(async ()=>{
    let response =await signoff({aid:val});
    if(response!=null){
      router.push({
        path: "/onSiteCheckIn",
        query: {
          sid: response.data.id,
          type:"2"
        },
      });
    }

  },2000)
}
const  toSignedDetails=(id)=> {
  router.push({
    path: "/signedDetails",
    query: {
      id
    },
  });
}
const finishedText = ref('');
const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const onRefresh = () => {
  query.value.pageNum = 1
  // 清空列表数据
  list.value = []
  finished.value = true
  finishedText.value = ''
  refreshing.value = true;
  onLoad()
};
const onLoad = () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  activityList();
}
const activityList = async ()=>{

  let response = await getSignupList(query.value,{status:"1"});
  loading.value = false
  refreshing.value = false
  console.log(response.rows)
  if (response.rows.length > 0) {

    list.value = list.value.concat(response.rows)
    query.value.pageNum++
    finished.value = false
    list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
  } else {
    // 数据全部加载完成
    finished.value = true
  }
  isEmpty.value = list.value.length === 0;
}
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 90px) !important;
  overflow-y: scroll;
  padding: 10px;

  &_item {
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    padding: 10px 10px 15px 10px;
    margin-bottom: 15px;
    &_top {
      display: flex;
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
      }

      &_right {
        width: 245px;
        display: flex;
        flex-direction: column;
        padding: 0 10px 0 10px;
        &_content {
          text-align: left;
          color: #212121;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 12px;
        }
        &_number {
          width: 230px;
          background-color: rgba(255, 145, 0, 0.05); /* 不支持线性的时候显示 */
          background-image: linear-gradient(to right, rgba(255, 145, 0, 0.05), #ffffff);
          display: flex;
          align-items: center;
          border-radius: 2px;
          line-height: 24px;
          padding: 4px 5px 4px 5px;
          &_left {
            line-height: 16px;
            color: rgba(244, 190, 72, 100);
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-regular;
            font-weight: bolder;
          }
        }
        &_time {
          margin-top: 10px;
          display: flex;
          &_left{
            flex: 1;
            line-height: 16px;
            color: rgba(33, 33, 33, 100);
            font-size: 10px;
            font-family: PingFangSC-regular;
            text-align: left;
          }
          &_right{
            line-height: 16px;
            border-radius: 2px;
            background-color: rgba(98, 212, 204, 100);
            font-size: 10px;
            text-align: center;
            color: rgba(255, 255, 255, 100);
          }
          &_right_two1{
            line-height: 16px;
            border-radius: 2px;
            background-color: rgba(98, 212, 204, 100);
            text-align: center;
            font-size: 10px;
            color: rgba(255, 255, 255, 100);
          }
          &_right_two2{
            line-height: 16px;
            border-radius: 2px;
            background-color: rgba(0, 230, 118, 100);
            text-align: center;
            font-size: 10px;
            color: rgba(255, 255, 255, 100);
          }
          &_right_two3{
            line-height: 16px;
            border-radius: 2px;
            background-color: rgba(33, 33, 33, 100);
            text-align: center;
            font-size: 10px;
            color: rgba(255, 255, 255, 100);
          }
          &_right_two4{
            line-height: 16px;
            border-radius: 2px;
            background-color: #ff9959;
            text-align: center;
            font-size: 10px;
            color: rgba(255, 255, 255, 100);
          }
        }
      }
    }

    &_address {
      margin-top: 20px;
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
      background-color: rgba(250, 250, 250, 100);

      &_content {
        text-align: left;
        margin-left: 10px;
        color: rgba(189, 189, 189, 100);
        font-size: 12px;
        font-weight: 550;
      }
    }

    &_user {
      line-height: 25px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }

      &_put {
        color: rgba(185, 190, 204, 100);
        font-size: 12px;
        text-align: left;
        margin-left: 8px;
        flex: 1;
      }

      .van-button {
        height: 23px;
        width: 60px;
        border-radius: 18px;
        font-size: 14px;
        text-align: center;
        font-family: Microsoft Yahei;
      }
      &_time{
        color: rgba(33, 33, 33, 100);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
  }
}
</style>
