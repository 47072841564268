<template>
  <div class="container">
    <div class="container_list">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
      >
        <van-list
            v-if="!isempty"
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          class="list"
        >
          <template
            v-for="item in list"
            :key="item"
          >
            <div class="container_item" @touchstart="gotouchstart(item)" @touchmove="gotouchmove" @touchend="gotouchend">
              <div class="container_item_checkbox">
                <van-checkbox
                  :name="item.id"
                  icon-size="16px"
                  v-model="item.check"
                ></van-checkbox>
              </div>
              <div class="container_item_main">
                <div class="container_item_main_pic">
                  <van-image
                    :src="item?.vlShopGoods?.images[0].url"
                    alt=""
                    height="60px"
                    width="60px"
                  />
                </div>
                <div class="container_item_main_info">
              <div class="container_item_main_info_title">{{item.vlShopGoods.name}}</div>

              <template v-if="item.vlShopGoods.payType==='1'">
                <div class="container_item_main_info_price"  >&yen;{{item.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{item.vlShopGoods?.vlUnit?.unit}}</span></div>
                <div class="container_item_main_score"  >
                  <div class="container_item_main_score_desc" v-show="false">积分：- {{item.vlShopGoods.score}}</div>
                  <div class="container_item_main_score_handle">
                    <van-stepper
                      v-model="item.num"
                      button-size="22"
                      disable-input
                       @change="onValueChange(item)"
                    />
                  </div>
                </div>
                </template>

              <template v-else-if="item.vlShopGoods.payType==='2'">
              <div class="container_item_main_info_price"  v-if="1>2">&yen;{{item.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{item.vlShopGoods?.vlUnit?.unit}}</span></div>
              <div class="container_item_main_score"  >
                <div class="container_item_main_score_desc">积分：- {{item.vlShopGoods.score}}</div>
                <div class="container_item_main_score_handle">
                  <van-stepper
                    v-model="item.num"
                    button-size="22"
                    disable-input
                     @change="onValueChange(item)"
                  />
                </div>
              </div>
              </template>
            <template v-else-if="item.vlShopGoods.payType==='3'">
            <div class="container_item_main_info_price">&yen;{{item.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{item.vlShopGoods?.vlUnit?.unit}}</span></div>
            <div class="container_item_main_score"  >
              <div class="container_item_main_score_desc">积分：- {{item.vlShopGoods.score}}</div>
              <div class="container_item_main_score_handle">
                <van-stepper
                  v-model="item.num"
                  button-size="22"
                  disable-input
                   @change="onValueChange(item)"
                />
              </div>
            </div>
            </template>

                </div>
              </div>
            </div>
          </template>

        </van-list>
      </van-pull-refresh>
      <van-empty
          description="暂无信息"
          v-if="isempty"
      />
    </div>
    <div class="container_bottom">
      <div class="container_bottom_check">
        <van-checkbox
          v-model="checked"
          icon-size="16px"
          @click="checkAll"
        >全选</van-checkbox>
      </div>
      <div class="container_bottom_total">合计: <span class="container_bottom_total_price">￥{{totalPrice}} <span v-if="totalScore>0">+积分{{totalScore}}</span> </span></div>
      <div class="container_bottom_btn">
        <van-button
          round
          type="warning"
          size="normal"
          color="#FFCF1A"
          @click="toPay"
        >结算</van-button>
      </div>
    </div>
  </div>
</template>

<script setup name="ShopCart">
import { ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { getCart, updateCart,deleteShopCartById } from '../../api/shop';
import {Dialog} from "vant";
import {Toast} from "vant";
const list = ref([]);
const payList = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const checked = ref(false);
const totalPrice = ref(0);
const totalScore = ref(0);
const router = useRouter();
const isempty = ref(false)
const isclick = ref(true)
const islongClick = ref(false)
const timeOut = ref();
const onRefresh = () => {
  refreshing.value = true;
  onLoad();
};
const checkAll = () => {
  list.value.map(item => item.check = checked.value)
}
const onLoad = async () => {
  loading.value = true;
  let result = await getCart();
  console.log(result)
  if (result != null && result.code === 200) {
    loading.value = false;
    list.value = result.rows;
    refreshing.value = false;
    finished.value = true;
  }else if (result?.code === 410)  {
    loading.value = false;
    list.value = [];
    Toast("未查询到相关信息");
    finished.value = true;
    isempty.value = true;
  }else {
    loading.value = false;
    finished.value = true;
    isempty.value = true;
  }
}

const toPay = () => {
    for(let item of list.value){
        if (item!=null && item.check === true){
            payList.value.push(item)
        }
    }
    if(payList.value.length == 0){
      Toast("您未选择任何商品");
      return;
    }
  router.push({ name: "ShopPay", params: { list: JSON.stringify(payList.value),totalPrice: totalPrice.value,totalScore: totalScore.value } })
}

watchEffect(() => {
  let allcheck = true
  let price = 0;
  let score = 0;
  for (let item of list.value) {
    console.log("item.check==", item)
    if (!item.check) {
      allcheck = false
    } else {
      if(item.vlShopGoods.payType==="1"){
        price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
      }else if(item.vlShopGoods.payType==="2"){
        score += item.vlShopGoods.score * item.num
      } else{
        price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
        score += item.vlShopGoods.score * item.num
      }

    }
  }
  checked.value = allcheck
  totalPrice.value = price;
  totalScore.value = score;
})

const onValueChange = async (item) => {
  console.log("item==", item)
  let result = await updateCart(item);
  if (result.code === 200) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

}

const gotouchstart = async (item) =>{
  islongClick.value = true;
  clearTimeout(timeOut.value);
  timeOut.value = setTimeout(function () {
    //that.actionSheetShow=true;//修改删除弹窗出现
    Dialog.confirm({
      title: '提示',
      message:
          '确认删除此商品?',
    })
        .then(() => {
          // on confirm
          deleteShopCartItem(item.id);
        })
        .catch(() => {
          // on cancel
        });
    this.islongClick = false;
    this.isclick=false;
  }, 1000);
}
const gotouchend = ()=>{
  clearTimeout(timeOut.value)
}

const gotouchmove = ()=> {
  islongClick.value= false;
  isclick.value=false;
  console.log('手指移动了');
  clearTimeout(timeOut.value);
}

const deleteShopCartItem = async(id) =>{
  let result = await deleteShopCartById(id);
  if (result.code === 200) {
    onRefresh();
  }else {
    Toast(result.msg);
  }
}

</script>
<style lang="scss" scoped>
.container {
  position: relative;
  height: calc(100vh - 60px) !important;
  overflow-y: scroll;
  &_list {
    padding: 5px 10px 10px 10px;
  }
  &_item {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 10px 15px 10px;
    background: #ffffff;
    border-radius: 6px;
    &_main {
      display: flex;
      flex-direction: row;
      flex: 1;
      &_pic {
        height: 60px;
      }
      &_pic img {
        height: 60px;
      }
      &_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        &_title {
          font-size: 16px;
          color: #212121;
          font-weight: bold;
        }
        &_price {
          margin-top: 10px;
          color: #ff9100;
          font-size: 20px;
          &_tag {
            font-size: 12px;
            color: #9e9e9e;
            margin-left: 10px;
            line-height: 0;
          }
        }
      }
      &_score {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &_desc {
          color: #ff9100;
          font-size: 10px;
        }
      }
    }
  }
  &_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    background: white;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
    align-items: center;
    box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
    &_total {
      font-size: 16px;
      &_price {
        color: #ff9100;
      }
    }
    &_btn {
      width: 120px;
      height: 44px;
    }
  }
}
.van-checkbox__icon {
  height: 16px;
}
.van-button--normal {
  width: 120px;
  height: 44px;
}
.van-image {
  margin: 0 5px 0 5px;
  vertical-align: middle;
}
</style>
