<template>

  <div class="container">
    <ActivityDetail :activity="activity"></ActivityDetail>
    <div class="container_list">
      <div class="container_list_top">
        <img src="../../../assets/images/activity/a_comment.png" alt="">
        <div class="container_list_top_des">
          活动评价
        </div>
      </div>

      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-if="!isEmpty"
            v-model:loading="loading"
            :finished="finished"
            :finished-text="finishedText"
            @load="onLoad"
            class="list"
        >
          <div class="list_item" v-for="(item, i) in list" :key="i">
            <div class="list_item_line"></div>
            <div class="list_item_user">
              <img :src="item?.fromUser?.avatar?(item?.fromUser?.avatar):avatarUrl" alt="">
              <div class="list_item_user_name">
                {{ item?.fromUser?.username }}
              </div>
              <div class="list_item_user_level"> {{ item?.fromUser?.vipRange?(item?.fromUser?.vipRange):"0" }}</div>
            </div>
            <div class="list_item_star">
              <van-rate
                  v-model="item.star"
                  :size="14"
                  color="#ff9100"
                  void-icon="star"
                  void-color="#eee"
                  readonly
              />
              <div class="list_item_star_time"> {{ item?.createTime.substring(0, 10) }}</div>
            </div>
            <div class="list_item_des">
              {{ item?.content }}
            </div>
            <div class="list_item_imgs" v-if="item.picList">
              <img v-for="(pic, i) in item.picList" :key="i" :src="pic" alt="">
            </div>
            <div class="list_item_flower">
              <img src="../../../assets/images/activity/flower.png" alt="">
              <div class="list_item_flower_des">
                赠送了 <span style="color: #ff3d00">{{ item?.score }}</span> 朵小红花
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <van-empty
          description="暂无信息"
          v-if="isEmpty"
      />
    </div>
  </div>
  <!--  按钮  -->
  <div class="container_bottom">
    <van-button @click="confirmBtn()">活动评价</van-button>
  </div>
</template>

<script setup>
import ActivityDetail from '../../../components/ActivityDetail.vue';
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";
import { getCommentList, getDetail} from "@/api/activity";
const avatarUrl = ref('../../../assets/images/activity/avatar.png');
const route = useRoute();
const router = useRouter();
const confirmBtn = () => {
  router.push({
    path: "/comment",
    query: {
      id
    },
  });
}
const id = route.query.id;
const activity=ref({});
const getAcDetail= async ()=>{
  let response=await getDetail(id);
  activity.value=response.data
/*  activity.value.starttime=activity.value.starttime.replace('-','年');
  activity.value.starttime=activity.value.starttime.replace('-','月')+'日';
  activity.value.endtime=activity.value.endtime.replace('-','年');
  activity.value.endtime=activity.value.endtime.replace('-','月')+'日';*/
}
getAcDetail();

const isEmpty = ref(false)
//分页
const query=ref({})
query.value= {pageNum: 1,pageSize: 10}
const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const finishedText = ref('');
const onRefresh = () => {
  query.value.pageNum = 1
  // 清空列表数据
  list.value = []
  finished.value = true
  finishedText.value = ''
  refreshing.value = true;
  onLoad()
};
const onLoad = () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  commentList()
};
const commentList = async ()=>{

  let response = await getCommentList(query.value,{aid:id});
  loading.value = false
  refreshing.value = false
  console.log(response.rows)
  if (response.rows.length > 0) {

    list.value = list.value.concat(response.rows)
    query.value.pageNum++
    finished.value = false
    list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
  } else {
    // 数据全部加载完成
    finished.value = true
  }
  isEmpty.value = list.value.length === 0;
}


</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 120px) !important;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 18px 10px;
  &_list {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    border-radius: 6px;
    padding: 10px;
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;

    &_top {
      padding: 18px 265px 18px 10px;
      background-color: rgba(255, 255, 255, 100);
      display: flex;
      align-items: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      img {
        width: 18px;
        height: 18px;
      }

      &_des {
        margin-left: 5px;
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-semiBold;
        font-weight: bolder;
      }
    }

    .list {
      display: flex;
      flex-direction: column;

      &_item {
        padding: 0 12px 20px 12px;
        background-color: rgba(255, 255, 255, 100);

        &_line {
          margin-bottom: 18px;
          width: 335px;
          height: 1px;
          line-height: 20px;
          border-radius: 6px;
          background-color: rgba(245, 245, 245, 100);
          color: rgba(16, 16, 16, 100);
          font-size: 14px;
          text-align: center;
          font-family: Arial;
        }

        &_user {
          display: flex;
          align-items: center;
          position: relative;

          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }

          &_name {
            margin-left: 12px;
            line-height: 24px;
            color: rgba(16, 16, 16, 100);
            font-size: 13px;
            text-align: left;
            font-family: SourceHanSansSC-regular;
          }

          &_level {
            position: absolute;
            flex: 1;
            width: 30px;
            height: 18px;
            left: 120px;
            line-height: 18px;
            border-radius: 2px;
            background-color: rgba(33, 33, 33, 100);
            text-align: center;
            color: rgba(255, 234, 0, 100);
            font-size: 10px;
            font-family: PingFangSC-semiBold;
            font-weight: bolder;
          }
        }

        &_star {
          margin-top: 15px;
          display: flex;
          align-items: center;

          .van-rate {
            flex: 1;
          }

          &_time {
            line-height: 14px;
            opacity: 0.38;
            color: rgba(16, 16, 16, 100);
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-regular;
          }
        }

        &_des {
          margin-top: 15px;
          line-height: 24px;
          color: rgba(16, 16, 16, 100);
          font-size: 14px;
          text-align: justify;
          font-family: SourceHanSansSC-regular;
        }

        &_imgs {
          margin-top: 15px;
          text-align: left;

          img {
            width: 105px;
            height: 60px;
            margin-right: 10px;
          }
        }

        &_flower {
          margin-top: 15px;
          line-height: 20px;
          border-radius: 2px;
          background-color: rgba(250, 250, 250, 100);
          color: rgba(16, 16, 16, 100);
          font-size: 14px;
          text-align: center;
          font-family: Arial;
          padding: 8px 10px 8px 10px;
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
          }

          &_des {
            margin-left: 5px;
            line-height: 14px;
            color: rgba(189, 189, 189, 100);
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-regular;
          }
        }
      }
    }
  }
}

.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #FFBF1E;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
