<template>
  <div class="container">
    <div class="container_star">
      <div class="container_star_des">活动评价</div>
      <van-rate
          @change="onChange"
          v-model="star"
          :size="18"
          color="#ff9100"
          void-icon="star"
          void-color="#eee"
      />
      <div class="container_star_name">{{starDes}}</div>
    </div>
    <div class="container_info">
      <div class="container_info_des">
        <img src="../../../assets/images/activity/input_deep.png" alt="">
        <div class="container_info_des_name">
          评价信息
        </div>
      </div>
      <van-field
          v-model="content"
          rows="3"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请输入"
          show-word-limit
      />
    </div>
    <div class="container_img">
      <div class="container_img_des">
        <img src="../../../assets/images/activity/img.png" alt="">
        <div class="container_img_des_name">活动照片</div>
      </div>
      <van-uploader v-model="fileList" multiple :before-read="beforeRead" :after-read="afterRead"  @delete="deleteImage"/>
    </div>
    <div class="container_flower">
      <div class="container_flower_des">
        给活动赠送小红花
      </div>
      <van-stepper v-model="flower" theme="round" button-size="22" disable-input ></van-stepper>
      <div class="container_flower_cue">当前还有{{ user.score }}朵小红花可赠送</div>
      <div class="container_flower_hint">
        <img src="../../../assets/images/activity/flower.png" alt="">
        <div class="container_flower_hint_name">
          一朵小红花等于1积分
        </div>
      </div>
    </div>
  </div>
  <div class="container_bottom">
    <van-button @click="submit">提交</van-button>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {Toast} from "vant";
import {addComment} from "@/api/activity";
import {useRoute, useRouter} from "vue-router";
import {uploadFiles} from "@/api/upload";

const route = useRoute();
const router = useRouter();
const id = route.query.id;

import {getUserInfo} from "@/api/user";
const user=ref({});
const getUser=async ()=>{
  let response = await getUserInfo();
  user.value=response.data;
}
getUser()
const submit=()=>{
  Toast.loading({
    message: '提交中...',
    forbidClick: true,
  });
  setTimeout(async ()=>{
    let data={
      aid:id,
      star:star.value,
      score:flower.value,
      picUrls:files.value,
      content:content.value,
    }
    let response =await addComment(data);
    router.push({
      path: "/activityComment",
    });
    console.log(response)
  })
}

const deleteImage=(file, detail)=>{
  files.value.splice(detail.index, 1)
}
const afterRead=async (file)=> {
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  formData.append('file', file.file)
  let response =await uploadFiles(formData);
  files.value.push(response.url)
}
const beforeRead=(file)=>{
  if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
    return true
  }
  Toast('请上传图片')
  return false
}


const fileList = ref([]);
const files = ref([]);
const star = ref("0");
const flower = ref(0);
const starDes = ref("差评");
const onChange = (value) => {
  if(value=="0"||value=="1"||value=="2"){
    starDes.value="差评"
  }else if(value=="3"){
    starDes.value="还行"
  }else {
    starDes.value="非常好"
  }
};
const content = ref('');
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 130px) !important;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 10px;

  &_star {
    display: flex;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    padding: 20px 10px 20px 10px;

    &_des {
      line-height: 18px;
      color: rgba(33, 33, 33, 100);
      font-size: 16px;
      text-align: left;
      font-family: PingFangSC-regular;
    }

    .van-rate {
      line-height: 18px;
      margin-left: 10px;
      margin-right: 10px;
    }

    &_name {
      line-height: 18px;
      color: rgba(189, 189, 189, 100);
      font-size: 12px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
  }

  &_info {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    padding: 18px 10px 18px 10px;

    &_des {
      display: flex;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
      }

      &_name {
        margin-left: 5px;
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
  }
  &_img{
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    padding: 18px 10px 5px 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_des{
      display: flex;
      align-items: center;
      img{
        width: 14px;
        height: 14px;
      }
      &_name{
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
        margin-left: 5px;
      }
    }
    .van-uploader{
      margin-top: 18px;
    }
  }
  &_flower{
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    margin-top: 10px;
    padding: 20px 87px 20px 87px;
    &_des{
      line-height: 18px;
      color: rgba(66, 66, 66, 100);
      font-size: 16px;
      text-align: center;
      font-family: PingFangSC-medium;
      font-weight: bolder;
      margin-bottom: 20px;
    }
    &_cue{
      line-height: 12px;
      color: rgba(117, 117, 117, 100);
      font-size: 12px;
      text-align: left;
      font-family: PingFangSC-regular;
      margin-top: 15px;
      margin-bottom: 25px;
    }
    &_hint{
      display: flex;
      align-items: center;
      img{
        width: 12px;
        height: 12px;
      }
      &_name{
        margin-left: 3px;
        line-height: 12px;
        color: rgba(189, 189, 189, 100);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
    .van-stepper{
      background-color: rgba(245, 245, 245, 100);
      width: 180px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 25px;
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      font-family: Arial;
    }
    ::v-deep .van-stepper--round .van-stepper__minus{
      background-color: #ff9100;
      color: rgba(255, 255, 255, 100);
      border: none;
      margin-right: 25px;
    }
    ::v-deep .van-stepper--round .van-stepper__plus{
      background-color: #ff9100;
      color: rgba(255, 255, 255, 100);
      margin-left: 25px;
      border: none;
    }
    ::v-deep .van-stepper--round .van-stepper__input{
      color: rgba(0, 0, 0, 1);
      font-size: 18px;
      text-align: center;
      font-family: PingFangSC-semiBold;
    }
  }

}
.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #FFBF1E;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
