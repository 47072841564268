<!--积分记录-->
<template>
  <div class="container">
    <div class="container_top">
      <div class="container_top_firstline">
        <span class="container_top_firstline_deco"><img src="../../assets/images/center/deco.png" alt=""></span><span
          class="container_top_firstline_title">可用积分</span>
      </div>
      <div class="container_top_secondline">
        {{ user.score }}
      </div>
    </div>

    <div class="container_main">
      <div class="container_main_title">积分明细</div>
      <div class="container_main_divider">
        <van-divider/>
      </div>
      <div class="container_main_list">
        <!--  列表  -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-if="!isEmpty"
            v-model:loading="loading"
            :finished="finished"
            :finished-text="finishedText"
            @load="onLoad"
            class="list"
        >
          <div class="container_main_list_item" v-for="item in list" :key="item">
            <div class="container_main_list_item_firstLine">
              <div class="container_main_list_item_firstLine_title">
                {{ item.type==="1"?"参加活动":(item.type==="2"?"邀请好友":(item.type==="3"?"评价活动":"购买商品")) }}
              </div>
              <div :class="[item.changeType==='1'?'container_main_list_item_firstLine_score2':'container_main_list_item_firstLine_score1']">{{ item.changeType==="1"?"-":"+" }}{{ item?.changeScore }}</div>
            </div>
            <div class="container_main_list_item_secondLine">
              <div class="container_main_list_item_secondLine_title">
                {{ (item.type==="1"||item.type==="3")?(item?.activity?.name):(item.type==="2"?("好友"+item?.vlUser?.phone):"")}}
              </div>
              <div class="container_main_list_item_secondLine_date">{{item.createTime.substring(0, 10).replaceAll('-','.')}}</div>
            </div>
            <div class="container_main_divider">
              <van-divider/>
            </div>
          </div>
        </van-list>
        </van-pull-refresh>
        <van-empty
            description="暂无信息"
            v-if="isEmpty"
        />
      </div>
    </div>
  </div>
</template>
<script setup name="ScoreRecord">
import {ref} from "@vue/reactivity";
import {getScoreRecord} from "@/api/score";
import {getUserInfo} from "@/api/user";
const user=ref({})
const getUser=async()=>{
  let response=await getUserInfo();
  user.value=response.data
}
getUser();
const isEmpty = ref(false)
//分页
const query=ref({})
query.value= {pageNum: 1,pageSize: 10}
const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const finishedText = ref('');

const onRefresh = () => {
  query.value.pageNum = 1
  // 清空列表数据
  list.value = []
  finished.value = true
  finishedText.value = ''
  refreshing.value = true;
  onLoad();
};
const onLoad = () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  scoreRecord();
};
const scoreRecord = async ()=>{

  let response = await getScoreRecord(query.value);
  loading.value = false
  refreshing.value = false
  console.log(response.rows)
  if (response.rows.length > 0) {

    list.value = list.value.concat(response.rows)
    query.value.pageNum++
    finished.value = false
    list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
  } else {
    // 数据全部加载完成
    finished.value = true
  }
  isEmpty.value = list.value.length === 0;
}
</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 100px) !important;
  overflow-y: scroll;

  &_top {
    padding: 15px 15px 15px 15px;

    &_firstline {
      display: flex;
      flex-direction: row;
      align-items: center;

      &_deco {
        margin-right: 5px;
      }

      &_deco img {
        height: 14px;
      }

      &_title {
        font-weight: bold;
        font-size: 12px;
        color: #212121;
      }
    }

    &_secondline {
      font-size: 28px;
      color: #F4BE48;
      margin-top: 10px;
    }
  }

  &_main {
    margin: 5px 10px 10px 10px;
    padding: 15px 15px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    &_title {
      color: #212121;
      font-size: 16px;
      font-weight: bold;
    }

    &_list {
      &_item {
        display: flex;
        flex-direction: column;

        &_firstLine {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &_title {
            font-size: 14px;
            color: #212121;
          }

          &_score1 {
            font-size: 14px;
            color: #F4BE48;
          }
          &_score2 {
            font-size: 14px;
            color: rgba(33, 33, 33, 100);
          }
        }

        &_secondLine {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &_title {
            font-size: 10px;
            color: #BDBDBD;
          }

          &_date {
            font-size: 10px;
            color: #BDBDBD;
          }
        }
      }
    }

  }
}
</style>


