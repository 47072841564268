import request from '@/utils/request'

export function getShopCates(query){
  return request({
      url: '/appreq/shopCategory/getCategoryList',
      headers: {
          isToken: false
        },
      method: 'get',
      params: query
  })
}

export function getShopGoods(query){
  return request({
      url: '/appreq/shopGoods/getGoosdList',
      headers: {
          isToken: false
        },
      method: 'get',
      params: query
  })
}

export function getShopGoodsById(id){
  return request({
      url: '/appreq/shopGoods/'+id,
      headers: {
          isToken: false
        },
      method: 'get'
  })
}
//添加到购物车
export function addCart(data){
  return request({
      url: '/appreq/shopCart/addCart',
      method: 'post',
      data
  })
}
//获取购物车列表
export function getCart(query){
  return request({
      url: '/appreq/shopCart/getCartList',
      method: 'get',
      params: query
  })
}

export function updateCart(data){
  return request({
      url: '/appreq/shopCart/updateCart',
      method: 'post',
      data
  })
}

//获取收货地址
export function getReceiveAddress(query){
  return request({
      url: '/appreq/shopAddress/getReceiveAddressList',
      method: 'get',
      params: query
  })
}

//新增收货地址
export function addAddress(data){
  return request({
      url: '/appreq/shopAddress/addAddress',
      method: 'post',
      data
  })
}

//更新收货地址
export function updateAddress(data){
  return request({
      url: '/appreq/shopAddress/updateAddress',
      method: 'post',
      data
  })
}

export function getAddressById(id){
  return request({
      url: '/appreq/shopAddress/getAddressById/'+id,
      method: 'get'
  })
}

export function getDefaultAddress(){
  return request({
      url: '/appreq/shopAddress/getDefaultAddress',
      method: 'get'
  })
}

export function deleteAddressById(id){
  return request({
      url: '/appreq/shopAddress/deleteAddressById/'+id,
      method: 'get'
  })
}

//获取我的商品兑换列表
export function getMyExchange(query){
    return request({
        url: '/appreq/shopOrder/getMyExchange',
        headers: {
            isToken: true
        },
        method: 'get',
        params: query
    })
}

//获取我的已经兑换的积分
export function getMyExchangeScore(){
    return request({
        url: '/appreq/shopOrder/getMyExchangeScore',
        method: 'get',
    })
}

//删除购物车内容
export function deleteShopCartById(id){
    return request({
        url: '/appreq/shopCart/deleteShopCartById/'+id,
        method: 'get',
    })
}
