<template>
  <div class="container">
    <img src="../../assets/images/wx_login.png" alt=""/>
    <div class="container_text">
      获取你的公开信息(昵称、头像等)
    </div>
    <div class="container_buttom">
      <van-button @click="confirmBtn">确认授权</van-button>
    </div>

  </div>
</template>
<script setup>
  import {setToken, setOpenid} from "../../utils/auth";
  import {useRouter} from "vue-router";
  import { mpLogin } from "../../api/user";
  import {ref} from "vue";
  const router = useRouter();
  const code = ref("");
  const state = ref("");
  const getUrlData = ()=>{
    var url = window.location.href ;
    var cs = url.split('?')[1];                //获取?之后的参数字符串
    console.error('cs'+cs);
    var cs_arr = cs.split('&');
    console.error('cs_arr'+cs_arr);
    var codeRes = cs_arr[0].substring(5,cs_arr[0].length);
    console.error('code-->'+codeRes);
    code.value = codeRes.toString();
    var stateRes = cs_arr[1].substring(6,cs_arr[1].length);
    console.error(stateRes);
    state.value = stateRes.toString();
  }

  const confirmBtn=()=>{
    mpLogins(code.value,state.value);
  }

  const mpLogins = async (code,state)=>{
    let response = await mpLogin(code,state);
    if(response!=null && response.code==200){
      if(response.data.token != null){
        setToken(response.data.token)
        setOpenid(response.data.user.openid)
        if(state === 'wxlogin#/wxLogin'){
          router.push({ name: 'Home' })
        }else {
          router.go(-1);
        }
      }else {
        router.push({ name: 'BindingPhone',params:{user: JSON.stringify(response.data.user),state:state} })
      }


    }else {
      alert(response.msg)
    }
  }




  getUrlData();

</script>

<style lang="scss" scoped>
  .container{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px)!important;
  overflow-y: scroll;
  img{
    width: 100%;
  }
    &_text{
      color: rgba(189, 189, 189, 100);
      margin:10px 0 0 18px ;
    }
    &_buttom{
      text-align: center;
      .van-button {
        margin: 20px 0 10px 0;
        width: 95%;
        height: 44px;
        border-radius: 6px;
        background-color: #07c160;
        color: rgba(255, 255, 255, 100);
        font-size: 14px;
        font-family: PingFangSC-semiBold;
        font-weight: bolder;

      }
    }

}
</style>
