<template>
  <!--我的发布-->
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-if="!isEmpty"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad"
        class="container"
    >
      <div class="container_acticity_item" @click="toDetail(item.activity.id)" v-for="(item, i) in list" :key="i" >
        <div class="container_acticity_item_left">
          <img :src="(item?.activity?.picUrl)?(item.activity?.picUrl):picUrl" alt="">
        </div>
        <div class="container_acticity_item_right">
          <div class="container_acticity_item_right_title">
            <div class="container_acticity_item_right_title_span">
              {{ item?.activity?.name }}
            </div>
            <div class="container_acticity_item_right_title_type">
              {{ item?.activity?.activityType?.name }}
            </div>
          </div>
          <div class="container_acticity_item_right_info">
            <div class="container_acticity_item_right_info_state">志愿服务时长: {{ item?.user.timeLen }}小时</div>
            <div class="container_acticity_item_right_info_date">{{ item?.activity?.scoreType==="1"?"+":"-" }}{{ item?.activity?.score }}积分</div>
          </div>
        </div>
      </div>

    </van-list>
  </van-pull-refresh>
  <van-empty
      description="暂无信息"
      v-if="isEmpty"
  />
</template>
<script setup name="MyJoin">
  import {ref} from "@vue/reactivity";
  import {getSignupList} from "@/api/activity";
  import {useRouter} from "vue-router";
  const finishedText = ref('');
  const list = ref([]);
  const loading = ref(false);
  const refreshing = ref(false);
  const finished = ref(false);
  const isEmpty = ref(false)
  const picUrl = ref('../../assets/images/activity/activity.png');
  //分页
  const query=ref({})
  query.value= {pageNum: 1,pageSize: 10}

  const onRefresh = () => {
    query.value.pageNum = 1
    // 清空列表数据
    list.value = []
    finished.value = true
    finishedText.value = ''
    refreshing.value = true;
    onLoad();
  };
  const onLoad = () => {
    loading.value = true
    finished.value = false
    refreshing.value = false
    activityList();
  };
  const activityList = async ()=>{

    let response = await getSignupList(query.value,{status:"1"});
    loading.value = false
    refreshing.value = false
    console.log(response.rows)
    if (response.rows.length > 0) {

      list.value = list.value.concat(response.rows)
      query.value.pageNum++
      finished.value = false
      list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
    } else {
      // 数据全部加载完成
      finished.value = true
    }
    isEmpty.value = list.value.length === 0;
  }
  const router = useRouter();
  const  toDetail=(id)=> {
    router.push({
      path: "/activityDetails",
      query: {
        id
      },
    });
  }
</script>
<style lang="scss" scoped>
.container{
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
  &_acticity_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 10px;
    padding: 10px 10px;
    background: #fff;
    border-radius: 6px;
    &_left{
      margin-right: 10px;
      height: 80px;
      img{
        height: 80px;
        width: 80px;
      }
    }
    &_right{
      width: 255px;
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_title{
        display: flex;
        &_span{
          flex: 1;
          font-size: 14px;
          color: #212121;
          font-weight: bolder;
        }
        &_type{
          margin-left: 20px;
          width: 59px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          font-weight: bolder;
          color: rgba(244, 190, 72, 100);
          border-radius: 2px;
          background-color: rgba(244, 190, 72, 0.1);
          text-align: center;
        }
      }
      &_info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &_state{
          font-size: 10px;
          border-radius: 2px;
          padding: 2px 2px;
          color: #B9BECC;
        }
        &_date{
          color: rgba(244, 190, 72, 100);
          font-size: 12px;
          text-align: left;
          font-family: PingFangSC-medium;
        }
      }

    }
  }
}

</style>
