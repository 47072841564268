<template>
  <div class="container">
    <!--    顶部不能输入-->
    <div class="container_top">
      <div class="container_top_item">
        <div class="container_top_item_name">
          所属站点
        </div>
        <div class="container_top_item_right">
          <div class="container_top_item_right_des">
            <van-field
              v-model="stationName"
              input-align="left"
              placeholder="请输入"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="container_top_line"></div>
      <div class="container_top_item">
        <div class="container_top_item_name">
          所属服务队
        </div>
        <div class="container_top_item_right">
          <div class="container_top_item_right_des">
            <van-field
              v-model="teamName"
              input-align="left"
              placeholder="请输入"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="container_top_line"></div>
      <div class="container_top_item">
        <div class="container_top_item_name">
          用户类型
        </div>
        <div class="container_top_item_right">
          <div class="container_top_item_right_des">
            <van-field
              v-model="userInfo.type"
              input-align="left"
              placeholder="请输入"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <!--中部第一部分-->
    <div class="container_info">
      <div class="container_info_item">
        <div class="container_info_item_name">
          会员名
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.username"
              input-align="left"
              placeholder="请输入会员名"
            />
          </div>
          <img
            src="../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          真实姓名
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.truename"
              input-align="left"
              placeholder="请输入真实姓名"
            />
          </div>
          <img
            src="../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          性别
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.sex"
              input-align="left"
              placeholder="请选择性别"
              readonly
              @click="sexShow = true"
            />
          </div>
          <img
            src="../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          出生年月
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.birthday"
              input-align="left"
              placeholder="请选择"
              readonly
              @click="birthdayShow = true"
            />
          </div>
          <img
            src="../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          身份证号
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.idCard"
              input-align="left"
              placeholder="请输入身份证"
            />
          </div>
          <img
            src="../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          政治面貌
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.political"
              input-align="left"
              placeholder="请输入政治面貌"
              readonly
              @click="politicalShow = true"
            />
          </div>
          <img
            src="../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          民族
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.nation"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          籍贯
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.birthplace"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          宗教信仰
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.belief"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>

    </div>
    <!--中部第二部分-->
    <div class="container_info">
      <div class="container_info_item">
        <div class="container_info_item_name">
          毕业学校
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.school"
              input-align="left"
              placeholder="请输入毕业学校"
            />
          </div>
          <img
            src="../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          专业
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.professional"
              input-align="left"
              placeholder="请输入专业"
            />
          </div>
          <img
            src="../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          学历
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.education"
              input-align="left"
              placeholder="请选择学历"
              readonly
              @click="eduShow = true"
            />
          </div>
          <img
            src="../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <!--    底部部分-->
    <div class="container_info">
      <div class="container_info_item">
        <div class="container_info_item_name">
          工作单位
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.employer"
              input-align="left"
              placeholder="请输入工作单位"
            />
          </div>
          <img
            src="../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          职务
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.job"
              input-align="left"
              placeholder="请选择职业"
              readonly
              @click="jobShow = true"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          技能
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.skill"
              input-align="left"
              placeholder="请输入技能"
            />
          </div>
          <img
            src="../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          兴趣爱好
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
              v-model="userInfo.hobby"
              input-align="left"
              placeholder="请输入兴趣爱好"
            />
          </div>
          <img
            src="../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item_image">
        <div class="container_info_item_image_name">
          真实头像
        </div>
        <div class="container_info_item_image_right">
          <div class="container_center_upload"><van-uploader v-model="fileList" :max-count="1" :before-read="beforeRead" :after-read="afterRead" multiple  @delete="deleteImage"/></div>
        </div>
      </div>

      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          户口所在地
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.hukou"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>

      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          暂住证号码
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.trp"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>

      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          邮编
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.postcode"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          通信地址
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.correspond"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          email
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.email"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          微信号
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.wechat"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          单位、学校电话
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.unitPhone"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          其他联系方式
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.otherPhone"
                input-align="left"
                placeholder="请输入"
            />
          </div>
          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >
        </div>
      </div>
    </div>
    <!-- 服务简历   -->
    <div class="container_info">
      <div class="container_info_resume">
        <div class="container_info_resume_name">
          服务简历
        </div>
      </div>
      <div class="container_info_resume_input">
        <van-field
            v-model="userInfo.resume"
            rows="3"
            autosize
            type="textarea"
            placeholder="请输入"
        />
      </div>
    </div>
<!-- 服务时段   -->
    <div class="container_info">
      <div class="container_info_item">
        <div class="container_info_item_title">
          服务时段
        </div>
      </div>
      <!-- 周一     -->
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          周一
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.periodList.weekOne"
                input-align="left"
                placeholder="请选择"
                readonly
                @click="toClickPeriod('1')"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <!-- 周二     -->
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          周二
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.periodList.weekTwo"
                input-align="left"
                placeholder="请选择"
                readonly
                @click="toClickPeriod('2')"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <!-- 周三     -->
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          周三
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.periodList.weekThree"
                input-align="left"
                placeholder="请选择"
                readonly
                @click="toClickPeriod('3')"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <!-- 周四     -->
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          周四
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.periodList.weekFour"
                input-align="left"
                placeholder="请选择"
                readonly
                @click="toClickPeriod('4')"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <!-- 周五     -->
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          周五
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.periodList.weekFive"
                input-align="left"
                placeholder="请选择"
                readonly
                @click="toClickPeriod('5')"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <!-- 周六    -->
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          周六
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.periodList.weekSix"
                input-align="left"
                placeholder="请选择"
                readonly
                @click="toClickPeriod('6')"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <!-- 周日     -->
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          周日
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.periodList.weekSun"
                input-align="left"
                placeholder="请选择"
                readonly
                @click="toClickPeriod('7')"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
    </div>
<!-- 服务项目   -->
    <div class="container_info">
      <div class="container_info_item">
        <div class="container_info_item_title">
          常规可从事志愿服务项目
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          服务项目
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="userInfo.projectList"
                input-align="left"
                placeholder="请选择"
                readonly
                @click="projectShow = true"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
    </div>

  </div>
  <!-- 底部按钮 -->
  <div class="container_bottom">
    <van-button @click="saveData">保存</van-button>
  </div>


  <!--  选择服务时段-->
  <!--1  -->
  <van-popup
      v-model:show="periodShow1"
      position="bottom"
      :lock-scroll="false"
      :safe-area-inset-bottom="true"
      :style="{ height: '100%' }"
  >
    <ChoosePeriod1
        @toBack1="closeShowPeriod1"
        @toChoosePeriod1="toChoosePeriod1"
    ></ChoosePeriod1>
  </van-popup>
<!-- 2 -->
  <van-popup
      v-model:show="periodShow2"
      position="bottom"
      :lock-scroll="false"
      :safe-area-inset-bottom="true"
      :style="{ height: '100%' }"
  >
    <ChoosePeriod2
        @toBack2="closeShowPeriod2"
        @toChoosePeriod2="toChoosePeriod2"
    ></ChoosePeriod2>
  </van-popup>
<!-- 3 -->
  <van-popup
      v-model:show="periodShow3"
      position="bottom"
      :lock-scroll="false"
      :safe-area-inset-bottom="true"
      :style="{ height: '100%' }"
  >
    <ChoosePeriod3
        @toBack3="closeShowPeriod3"
        @toChoosePeriod3="toChoosePeriod3"
    ></ChoosePeriod3>
  </van-popup>
<!-- 4 -->
  <van-popup
      v-model:show="periodShow4"
      position="bottom"
      :lock-scroll="false"
      :safe-area-inset-bottom="true"
      :style="{ height: '100%' }"
  >
    <ChoosePeriod4
        @toBack4="closeShowPeriod4"
        @toChoosePeriod4="toChoosePeriod4"
    ></ChoosePeriod4>
  </van-popup>
<!-- 5 -->
  <van-popup
      v-model:show="periodShow5"
      position="bottom"
      :lock-scroll="false"
      :safe-area-inset-bottom="true"
      :style="{ height: '100%' }"
  >
    <ChoosePeriod5
        @toBack5="closeShowPeriod5"
        @toChoosePeriod5="toChoosePeriod5"
    ></ChoosePeriod5>
  </van-popup>
<!-- 6 -->
  <van-popup
      v-model:show="periodShow6"
      position="bottom"
      :lock-scroll="false"
      :safe-area-inset-bottom="true"
      :style="{ height: '100%' }"
  >
    <ChoosePeriod6
        @toBack6="closeShowPeriod6"
        @toChoosePeriod6="toChoosePeriod6"
    ></ChoosePeriod6>
  </van-popup>
<!-- 7 -->
  <van-popup
      v-model:show="periodShow7"
      position="bottom"
      :lock-scroll="false"
      :safe-area-inset-bottom="true"
      :style="{ height: '100%' }"
  >
    <ChoosePeriod7
        @toBack7="closeShowPeriod7"
        @toChoosePeriod7="toChoosePeriod7"
    ></ChoosePeriod7>
  </van-popup>

  <!--  选择服务项目-->
  <van-popup
      v-model:show="projectShow"
      position="bottom"
      :lock-scroll="false"
      :safe-area-inset-bottom="true"
      :style="{ height: '100%' }"
  >
    <ChooseProject
        :id="userId"
        @goBack="closeShowProject"
        @toChooseProject="toChooseProject"
    ></ChooseProject>
  </van-popup>
  <!--  选择生日-->
  <van-popup
    v-model:show="birthdayShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-datetime-picker
        v-model="currentDate"
        type="date"
        @cancel="birthdayShow = false"
        @confirm="confirmPickerBirthday"
    />
  </van-popup>
  <!--  选择性别-->
  <van-popup
    v-model:show="sexShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-picker
      title="选择性别"
      :columns="sexColumns"
      @confirm="onConfirmSex"
      @cancel="sexShow=false"
    />
  </van-popup>

  <!--  政治面貌-->
  <van-popup
    v-model:show="politicalShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-picker
      title="选择政治面貌"
      :columns="politicalColumns"
      @confirm="onConfirmPolitical"
      @cancel="politicalShow=false"
    />
  </van-popup>


  <!--  学历-->
  <van-popup
      v-model:show="eduShow"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-picker
        title="选择学历"
        :columns="eduColumns"
        @confirm="onConfirmEdu"
        @cancel="eduShow=false"
    />
  </van-popup>


  <!--  职业-->
  <van-popup
      v-model:show="jobShow"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-picker
        title="选择职业"
        :columns="jobColumns"
        @confirm="onConfirmJob"
        @cancel="jobShow=false"
    />
  </van-popup>

</template>
<script setup name="PersonInfo">
import { reactive, ref, toRefs } from "vue";
import { getUserInfo,updateUserInfo } from "@/api/user"
import { Toast } from "vant";
import { useRouter } from "vue-router";
import {uploadFiles} from "@/api/upload";
import ChooseProject from "@/views/personcenter/ChooseProject";
import ChoosePeriod1 from "@/views/personcenter/choosePeriod/ChoosePeriod1";
import ChoosePeriod2 from "@/views/personcenter/choosePeriod/ChoosePeriod2";
import ChoosePeriod3 from "@/views/personcenter/choosePeriod/ChoosePeriod3";
import ChoosePeriod4 from "@/views/personcenter/choosePeriod/ChoosePeriod4";
import ChoosePeriod5 from "@/views/personcenter/choosePeriod/ChoosePeriod5";
import ChoosePeriod6 from "@/views/personcenter/choosePeriod/ChoosePeriod6";
import ChoosePeriod7 from "@/views/personcenter/choosePeriod/ChoosePeriod7";

const router = useRouter();

//服务时段
const periodShow1= ref(false)
const closeShowPeriod1 = () => {
  periodShow1.value = false
}
const toChoosePeriod1 = (val) => {
  userInfo.value.periodList.weekOne=val
  periodShow1.value = false
}
const toClickPeriod = (val) => {
  if(val==='1'){
    periodShow1.value = true
  }else if(val==='2'){
    periodShow2.value = true
  }else if(val==='3'){
    periodShow3.value = true
  }else if(val==='4'){
    periodShow4.value = true
  }else if(val==='5'){
    periodShow5.value = true
  }else if(val==='6'){
    periodShow6.value = true
  }else {
    periodShow7.value = true
  }
}

const periodShow2= ref(false)
const closeShowPeriod2 = () => {
  periodShow2.value = false
}
const toChoosePeriod2 = (val) => {
  userInfo.value.periodList.weekTwo=val
  periodShow2.value = false
}

const periodShow3= ref(false)
const closeShowPeriod3 = () => {
  periodShow3.value = false
}
const toChoosePeriod3 = (val) => {
  userInfo.value.periodList.weekThree=val
  periodShow3.value = false
}

const periodShow4= ref(false)
const closeShowPeriod4 = () => {
  periodShow4.value = false
}
const toChoosePeriod4 = (val) => {
  userInfo.value.periodList.weekFour=val
  periodShow4.value = false
}



const periodShow5= ref(false)
const closeShowPeriod5 = () => {
  periodShow5.value = false
}
const toChoosePeriod5 = (val) => {
  userInfo.value.periodList.weekFive=val
  periodShow5.value = false
}


const periodShow6= ref(false)
const closeShowPeriod6 = () => {
  periodShow6.value = false
}
const toChoosePeriod6 = (val) => {
  userInfo.value.periodList.weekSix=val
  periodShow6.value = false
}


const periodShow7= ref(false)
const closeShowPeriod7 = () => {
  periodShow7.value = false
}
const toChoosePeriod7 = (val) => {
  userInfo.value.periodList.weekSeven=val
  periodShow7.value = false
}

//服务项目
const projectShow= ref(false)
const userId= ref("")
const closeShowProject = () => {
  projectShow.value = false
}
const toChooseProject = (val) => {
  console.log(val)
  userInfo.value.projectList=val
  projectShow.value = false
}

//生日
const birthdayShow = ref(false)
const currentDate = ref(new Date())
const confirmPickerBirthday = (val) => {
  const year = val.getFullYear() // 年
  let month = val.getMonth() + 1 // 月
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  let date = val.getDate()  // 日
  if (date >= 1 && date <= 9) {
    date = `0${date}`
  }
  userInfo.value.birthday = `${year}-${month}-${date}`
  birthdayShow.value = false
}
// //性别
const sexShow = ref(false);
const politicalShow = ref(false);
//学历
const eduShow = ref(false);
//职业
const jobShow = ref(false);

//站点名称
const stationName = ref(null);
//队伍名称
const teamName = ref(null);
const data = reactive({ userInfo: {
    periodList:{}
  } });

const fileList = ref([]);
const files = ref([]);
const sexColumns = ref([
  { id: 0, name: '男', text: "男" },
  { id: 1, name: '女', text: "女" }
]);
//政治面貌
const politicalColumns = ref([
  { id: 1, name: '党员', text: "党员" },
  { id: 2, name: '预备党员', text: "预备党员" },
  { id: 3, name: '共青团员', text: "共青团员" },
  { id: 4, name: '无党派人士', text: "无党派人士" },
  { id: 5, name: '群众', text: "群众" },
]);
//学历
const eduColumns = ref([
  { id: 1, name: '初中', text: "初中" },
  { id: 2, name: '中专/高中', text: "中专/高中" },
  { id: 3, name: '专科', text: "专科" },
  { id: 4, name: '本科', text: "本科" },
  { id: 5, name: '硕士', text: "硕士及编号" },
]);

//职业
const jobColumns = ref([
  { id: 1, name: '公务员', text: "公务员" },
  { id: 2, name: '国企员工', text: "国企员工" },
  { id: 3, name: '事业编', text: "事业编" },
  { id: 4, name: '民企员工', text: "民企员工" },
  { id: 5, name: '企业主', text: "企业主" },
  { id: 6, name: '自由职业', text: "自由职业" },
  { id: 7, name: '其他', text: "其他" },
]);

const getInfo = async () => {
  let result = await getUserInfo();
  if (result?.code === 200) {
    data.userInfo = result.data
    userId.value = result.data.id

    console.log("userINo===",result.data)
    if (data.userInfo.type != null) {
      if (data.userInfo.type === '1') {
        data.userInfo.type = '游客'
      } else if (data.userInfo.type === '2') {
        data.userInfo.type = '队员'
      } else if (data.userInfo.type === '3') {
        data.userInfo.type = '队长'
      } else if (data.userInfo.type === '4') {
        data.userInfo.type = '站长'
      } else if (data.userInfo.type === '5') {
        data.userInfo.type = '副队长'
      }
    }
    if (data.userInfo.sex != null) {
      if (data.userInfo.sex === '0') {
        data.userInfo.sex = '男'
      } else if (data.userInfo.sex === '1') {
        data.userInfo.sex = '女'
      }
    }
    //处理政治面貌
    if (data.userInfo.political != null) {
      if (data.userInfo.political === '1') {
        data.userInfo.political = '党员'
      } else if (data.userInfo.political === '2') {
        data.userInfo.political = '预备党员'
      } else if (data.userInfo.political === '3') {
        data.userInfo.political = '共青团员'
      }else if(data.userInfo.political === '4') {
        data.userInfo.political = '无党派人士'
      }else if(data.userInfo.political === '5'){
        data.userInfo.political = '群众'
      }
    }
    //处理学历
    if (data.userInfo.education != null) {
      if (data.userInfo.education === '1') {
        data.userInfo.education = '初中'
      } else if (data.userInfo.education === '2') {
        data.userInfo.education = '中专/高中'
      } else if (data.userInfo.education === '3') {
        data.userInfo.education = '专科'
      }else if(data.userInfo.education === '4') {
        data.userInfo.education = '本科'
      }else if(data.userInfo.education === '5'){
        data.userInfo.education = '硕士及以上'
      }
    }
    //处理职业
    if (data.userInfo.job != null) {
      if (data.userInfo.job === '1') {
        data.userInfo.job = '公务员'
      } else if (data.userInfo.job === '2') {
        data.userInfo.job = '国企员工'
      } else if (data.userInfo.job === '3') {
        data.userInfo.job = '事业编'
      }else if(data.userInfo.job === '4') {
        data.userInfo.job = '民企员工'
      }else if(data.userInfo.job === '5'){
        data.userInfo.job = '企业主'
      }else if(data.userInfo.job === '6'){
        data.userInfo.job = '自由职业'
      }else if(data.userInfo.job === '7'){
        data.userInfo.job = '其他'
      }
    }

    if(data.userInfo.vlStation != null){
      stationName.value = data.userInfo.vlStation.name;
    }else {
      stationName.value = null;
    }

    if(data.userInfo.vlTeam != null){
      teamName.value = data.userInfo.vlTeam.name;
    }else {
      teamName.value = null;
    }
    if(data.userInfo.trueHeadImg != null){
      console.error(data.userInfo.trueHeadImg);
      let item = {};
      item.url = data.userInfo.trueHeadImg;
      fileList.value.push(item);
      files.value.push(data.userInfo.trueHeadImg);
    }

  }
}
getInfo();
const { userInfo } = toRefs(data)
const onConfirmSex = (val) => {
  sexShow.value = false
  userInfo.value.sex = val.name
}
//政治面貌
const onConfirmPolitical = (val) => {
  userInfo.value.political = val.name
  userInfo.value.politicalId = val.id
  politicalShow.value = false
}

//学历
const onConfirmEdu = (val) => {
  userInfo.value.education = val.name
  userInfo.value.educationId = val.id
  eduShow.value = false
}

//职业
const onConfirmJob = (val) => {
  userInfo.value.job = val.name
  userInfo.value.jobId = val.id
  jobShow.value = false
}



const saveData = async ()=>{
      if(userInfo.value.projectList!=null&&userInfo.value.projectList!==""){
        console.log(userInfo.value.projectList)
        userInfo.value.projectList=userInfo.value.projectList.split(",")
      }
      if(userInfo.value.periodList!=null){
        console.log(userInfo.value.periodList)
        if(userInfo.value.periodList.weekOne!=null&&userInfo.value.periodList.weekOne!==''){
          userInfo.value.periodList.weekOne=userInfo.value.periodList.weekOne.split(",")
        }else {
          userInfo.value.periodList.weekOne=[]
        }
        if(userInfo.value.periodList.weekTwo!=null&&userInfo.value.periodList.weekTwo!==''){
          userInfo.value.periodList.weekTwo=userInfo.value.periodList.weekTwo.split(",")
        }else {
          userInfo.value.periodList.weekTwo=[]
        }
        if(userInfo.value.periodList.weekThree!=null&&userInfo.value.periodList.weekThree!==''){
          userInfo.value.periodList.weekThree=userInfo.value.periodList.weekThree.split(",")
        }else {
          userInfo.value.periodList.weekThree=[]
        }
        if(userInfo.value.periodList.weekFour!=null&&userInfo.value.periodList.weekFour!==''){
          userInfo.value.periodList.weekFour=userInfo.value.periodList.weekFour.split(",")
        }else {
          userInfo.value.periodList.weekFour=[]
        }
        if(userInfo.value.periodList.weekFive!=null&&userInfo.value.periodList.weekFive!==''){
          userInfo.value.periodList.weekFive=userInfo.value.periodList.weekFive.split(",")
        }else {
          userInfo.value.periodList.weekFive=[]
        }
        if(userInfo.value.periodList.weekSix!=null&&userInfo.value.periodList.weekSix!==''){
          userInfo.value.periodList.weekSix=userInfo.value.periodList.weekSix.split(",")
        }else {
          userInfo.value.periodList.weekSix=[]
        }
        if(userInfo.value.periodList.weekSun!=null&&userInfo.value.periodList.weekSun!==''){
          userInfo.value.periodList.weekSun=userInfo.value.periodList.weekSun.split(",")
        }else {
          userInfo.value.periodList.weekSun=[]
        }
      }
      if (files.value.length===0) {
        Toast({
          message: '请上传真实头像',
          icon: 'none',
          duration: 2000
        })
        return false
      }
      if (userInfo.value.type != null) {
      if (userInfo.value.type === '游客') {
        userInfo.value.type = '1'
      } else if (userInfo.value.type === '队员') {
        userInfo.value.type = '2'
      } else if (userInfo.value.type === '队长') {
        userInfo.value.type = '3'
      } else if (userInfo.value.type === '站长') {
        userInfo.value.type = '4'
      } else if (userInfo.value.type === '副队长') {
        userInfo.value.type = '5'
      }
    }
    if (userInfo.value.sex != null) {
      if (userInfo.value.sex === '男') {
        userInfo.value.sex = '0'
      } else if (userInfo.value.sex === '女') {
        userInfo.value.sex = '1'
      }
    }

    if (userInfo.value.political != null) {
      if (userInfo.value.political === '党员') {
        userInfo.value.political = '1'
      } else if (userInfo.value.political === '预备党员') {
        userInfo.value.political = '2'
      } else if (userInfo.value.political === '共青团员') {
        userInfo.value.political = '3'
      }else if(userInfo.value.political === '无党派人士'){
        userInfo.value.political = '4'
      }else if(userInfo.value.political === '群众'){
        userInfo.value.political = '5'
      }
    }

    if (userInfo.value.education != null) {
      if (userInfo.value.education === '初中') {
        userInfo.value.education = '1'
      } else if (userInfo.value.education === '中专/高中') {
        userInfo.value.education = '2'
      } else if (userInfo.value.education === '专科') {
        userInfo.value.education = '3'
      }else if(userInfo.value.education === '本科'){
        userInfo.value.education = '4'
      }else if(userInfo.value.education === '硕士及以上'){
        userInfo.value.education = '5'
      }
    }


    if (userInfo.value.job != null) {
      if (userInfo.value.job === '公务员') {
        userInfo.value.job = '1'
      } else if (userInfo.value.job === '国企员工') {
        userInfo.value.job = '2'
      } else if (userInfo.value.job === '事业编') {
        userInfo.value.job = '3'
      }else if(userInfo.value.job === '民企员工'){
        userInfo.value.job = '4'
      }else if(userInfo.value.job === '企业主'){
        userInfo.value.job = '5'
      }else if(userInfo.value.job === '自由职业'){
        userInfo.value.job = '6'
      }else if(userInfo.value.job === '其他'){
        userInfo.value.job = '7'
      }
    }

    if (files.value.length!=0) {
      userInfo.value.trueHeadImg = files.value[0]
    }
  let result = await updateUserInfo(userInfo.value);
  if(result.code===200){
    Toast("更新成功")
    router.back()
  }

}

const deleteImage=(file, detail)=>{
  files.value.splice(detail.index, 1)
}
const afterRead=async (file)=> {
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  formData.append('file', file.file)
  let response =await uploadFiles(formData);
  files.value.push(response.url)
}
const beforeRead=(file)=>{
  if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
    return true
  }
  Toast('请上传图片')
  return false
}

</script>
<style lang="scss" scoped>
.van-background-default {
  background-color: rgba(245, 245, 245, 100);
  color: rgba(189, 189, 189, 100);
}

.van-background-checked {
  background-color: rgba(255, 145, 0, 100);
  color: rgba(255, 255, 255, 100);
}

.container {
  position: relative;
  height: calc(100vh - 130px) !important;
  overflow-y: scroll;
  padding: 0px 10px 10px 10px;

  &_top {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    padding: 18px 10px 18px 10px;

    &_item {
      display: flex;

      &_name {
        line-height: 18px;
        color: rgba(189, 189, 189, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
        flex: 2;
      }

      &_right {
        flex: 7;
        height: 20px;
        display: flex;
        align-items: center;

        &_des {
          line-height: 18px;
          color: rgba(117, 117, 117, 100);
          font-size: 14px;
          text-align: center;
          font-family: PingFangSC-medium;
          font-weight: bolder;
          flex: 1;

          .van-switch {
            float: right;
          }
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_line {
      margin-top: 18px;
      margin-bottom: 18px;
      border: 1px solid #f5f5f5;
      width: 335px;
    }
  }

  &_info {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    padding: 18px 10px 18px 10px;

    &_resume {
      display: flex;
      align-items: center;
      &_name {
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_item {
      display: flex;

      &_title{
        line-height: 18px;
        color: rgba(255, 145, 0, 100);
        font-size: 14px;
        text-align: left;
      }
      &_name {
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
        flex: 2;
      }

      &_right {
        flex: 7;
        height: 20px;
        display: flex;
        align-items: center;
        &_des {
          line-height: 18px;
          color: rgba(33, 33, 33, 100);
          font-size: 16px;
          text-align: center;
          font-family: PingFangSC-medium;
          font-weight: bolder;
          flex: 1;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_item_image {
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;

      &_name {
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
        flex: 2;
      }

      &_right {
        flex: 7;
        height: 20px;
        display: flex;
        align-items: center;
        &_des {
          line-height: 18px;
          color: rgba(33, 33, 33, 100);
          font-size: 16px;
          text-align: center;
          font-family: PingFangSC-medium;
          font-weight: bolder;
          flex: 1;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_line {
      margin-top: 18px;
      margin-bottom: 18px;
      border: 1px solid #f5f5f5;
      width: 335px;
    }
  }
}
.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #ffbf1e;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
