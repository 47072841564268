<template>
  <div class="container">
    <!--  头部  -->
    <div class="top">
      <div class="item" @click="toIssue" v-if="(user?.type!=='1'&&user?.type!=='2')">
        <img src="../../assets/images/activity/put.png" alt="">
        <div class="text">发布活动</div>
      </div>

<!--
    v-if="(user?.type!=='1'&&user?.type!=='2')"
    -->
      <div class="item" @click="toClaim" v-if="(user?.type!=='1'&&user?.type!=='2')">
        <img src="../../assets/images/activity/claim.png" alt="">
        <div class="text">认领活动</div>
      </div>
      <div class="item" @click="toSignedUp">
        <img src="../../assets/images/activity/signedup.png" alt="">
        <div class="text">已报名</div>
      </div>
      <div class="item" @click="toComment">
        <img src="../../assets/images/activity/comment.png" alt="">
        <div class="text">活动评价</div>
      </div>
    </div>
    <!--  中间  -->
    <div class="center">
      <div class="text_left">
        活动列表
      </div>
      <div class="text_right" @click="show = true">
        <div class="select" >筛选</div>
        <img src="../../assets/images/activity/select.png" alt="">
      </div>
    </div>
    <!--  列表  -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-if="!isEmpty"
          v-model:loading="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
          class="list"
      >
        <div class="item" @click="toDetail(item.id)" v-for="(item, i) in list" :key="i">
          <div class="top">
            <img :src="item.picUrl?item.picUrl:picUrl" alt="">
            <div class="right">
              <div class="content">
                {{ item.name }}
              </div>
              <div class="number">
                <div class="number_left">
                  <div class="sign">
                    已报名
                  </div>
                  <div class="num" v-if="item.alreaySignUpNum===0">
                    <div class="num_item" >0</div>
                  </div>
                  <div class="num" v-else>
                    <div class="num_item" v-for="(num, i) in (item.alreaySignUpNum.toString().split(''))" :key="i">{{ num }}</div>
                  </div>
                </div>
                <div class="number_right">
                  限{{ item.limitNum }}人
                </div>
              </div>
              <div class="time">
                <div class="time_left" >
                  报名截止：{{item?.signEndTime?.substring(0, 10).replaceAll('-','.')}}
                </div>
                <div  :class="[item.status==='0'?'time_right3':(item.status==='1'?'time_right2':(item.status==='2'?'time_right1':'time_right4'))]">
                  {{ item.status==='0'?'已结束':(item.status==='1'?'进行中':(item.status==='2'?'报名中':'未开始')) }}
                </div>
              </div>
            </div>
          </div>
          <div class="address">
            <div class="content">
              {{ item?.addressDetail?.length>25?(item?.addressDetail?.slice(0,25)+'...'):item?.addressDetail }}
            </div>
          </div>
          <div class="user">
            <img :src="item?.pubUser?.avatar?(item?.pubUser?.avatar):avatarUrl" alt="">
            <div class="put">
              {{ item?.pubUser?.username }}发布于{{item.createTime.substring(0, 10).replaceAll('-','.')}}
            </div>
            <div class="score">
              可{{item.scoreType==='1'?'获得':'消耗'}}{{item.score}}积分

            </div>
          </div>
        </div>

      </van-list>
    </van-pull-refresh>
    <van-empty
        description="暂无信息"
        v-if="isEmpty"
    />
  </div>
  <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-picker
        title="筛选"
        :columns="actType"
        @confirm="onConfirm"
        @cancel="show=false"
    />
  </van-popup>
</template>
<script setup>
import {ref} from 'vue';
import {useRouter} from "vue-router";
import {getActivityList, getActivityTypeList} from "@/api/activity";
import {getUserInfo} from "@/api/user";
import {useStore} from "vuex";
const store = useStore();
const user=ref({})
const getUser=async ()=>{
  let response=await getUserInfo();
  user.value=response.data
}
getUser();

const isEmpty = ref(false)
//分页
const query=ref({})
query.value= {pageNum: 1,pageSize: 10}


const avatarUrl = ref('../../assets/images/activity/avatar.png');
const picUrl = ref('../../assets/images/activity/activity.png');
const list = ref([]);
const finishedText = ref('');
const actTypeId = ref('');
const show = ref(false);
const actType = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);

const getAcTypeList= async ()=>{
  let response=await getActivityTypeList();
  actType.value=response.data
  if(actType.value.length>0){
    let s={
      id:'',
      name:'全部',
      text:'全部'
    }
    actType.value.unshift(s);
    for (let e of actType.value) {
      e.text = e.name
    }
  }
}
getAcTypeList();
const onConfirm = (val) => {
  actTypeId.value = val.id
  show.value = false
  onRefresh();
}

const onRefresh = () => {
  query.value.pageNum = 1
  // 清空列表数据
  list.value = []
  finished.value = true
  finishedText.value = ''
  refreshing.value = true;
  onLoad();
};
const router = useRouter();
const  toDetail=(id)=> {
  router.push({
    path: "/activityDetails",
    query: {
      id
    },
  });
}
const  toIssue=()=> {
  router.push({
    path: "/issueActivity"
  });
}
const  toClaim=()=> {
  router.push({
    path: "/claim"
  });
}
const  toSignedUp=()=> {
  router.push({
    path: "/signedUp"
  });
}
const  toComment=()=> {
  router.push({
    path: "/activityComment"
  });
}
const onLoad = () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  activityList();
}
const activityList = async ()=>{
  let sid;
  if(store.state.stationId == 'undefined' || store.state.stationId == 'null' || store.state.stationId == null){
    sid ='';
  }else {
    sid = store.state.stationId;
  }
  let response = await getActivityList(query.value,{stationId:sid,actTypeId:actTypeId.value,checkStatus:"1",oper:"1"});
  loading.value = false
  refreshing.value = false
  console.log(response.rows)
  if (response.rows.length > 0) {

    list.value = list.value.concat(response.rows)
    query.value.pageNum++
    finished.value = false
    list.value.length === 0 ? (finishedText.value = '') : (finishedText.value = '没有更多了')
  } else {
    // 数据全部加载完成
    finished.value = true
  }
  isEmpty.value = list.value.length === 0;
}
</script>
<style lang="scss" scoped>

.container {
  height: calc(100vh - 90px)!important;
  overflow-y: scroll;

  .top {
    background-color: #FFFFFF;
    width: 100%;
    height: 106px;
    display: flex;

    .item {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;

      img {
        width: 44px;
        height: 44px;
        margin-bottom: 10px;
      }

      .text {
        font-size: 12px;
        color: #212121;
      }
    }
  }

  .center {
    display: flex;
    margin-top: 20px;
    margin-bottom: 12px;

    .text_left {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      margin-left: 12px;
      flex: 1;
    }

    .text_right {
      display: flex;
      margin-right: 10px;
      align-items: center;

      .select {
        font-size: 16px;
        color: #616161;
        margin-right: 6px;
      }

      img {
        width: 14px;
        height: 14px;
        line-height: 14px;
      }
    }
  }

  .list {
    padding-left: 10px;
    padding-right: 10px;

    .item {
      height: 200px;
      background-color: #FFFFFF;
      border-radius: 6px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .top {
        display: flex;
        height: 90px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 4px;
          margin-left: 10px;
          margin-top: 10px;
        }

        .right {
          margin-left: 10px;

          .content {
            color: #212121;
            font-size: 14px;
            font-weight: 600;
            margin-top: 14px;
            margin-bottom: 12px;
          }

          .number {
            width: 245px;
            height: 24px;
            background-color: rgba(255, 145, 0, 0.05); /* 不支持线性的时候显示 */
            background-image: linear-gradient(to right, rgba(255, 145, 0, 0.05), #ffffff);
            display: flex;
            align-items: center;

            .number_left {
              display: flex;
              align-items: center;
              flex: 1;

              .sign {
                height: 16px;
                line-height: 16px;
                font-size: 12px;
                color: #F4BE48;
                margin-left: 5px;
              }

              .num {
                display: flex;
                margin-left: 5px;

                .num_item {
                  width: 16px;
                  border: 1px solid rgba(255, 145, 0, 20);
                  background-color: rgba(255, 255, 255, 100);
                  line-height: 16px;
                  border-radius: 2px;
                  margin-right: 3px;
                  height: 16px;
                  font-size: 12px;
                  font-weight: bolder;
                  text-align: center;
                  color: rgba(255, 145, 0, 100);
                }
              }
            }

            .number_right {
              height: 16px;
              line-height: 16px;
              font-size: 12px;
              color: rgba(244, 190, 72, 100);
            }
          }

          .time {
            display: flex;
            margin-top: 10px;

            .time_left {
              flex: 1;
              height: 16px;
              line-height: 16px;
              color: rgba(33, 33, 33, 100);
              font-size: 10px;
            }

            .time_right1 {
              height: 16px;
              line-height: 16px;
              border-radius: 2px;
              background-color: rgba(98, 212, 204, 100);
              font-size: 10px;
              text-align: center;
              color: rgba(255, 255, 255, 100);
            }
            .time_right2 {
              height: 16px;
              line-height: 16px;
              border-radius: 2px;
              background-color: rgba(0, 230, 118, 100);
              font-size: 10px;
              text-align: center;
              color: rgba(255, 255, 255, 100);
            }
            .time_right3 {
              height: 16px;
              line-height: 16px;
              border-radius: 2px;
              background-color: rgba(33, 33, 33, 100);
              font-size: 10px;
              text-align: center;
              color: rgba(255, 255, 255, 100);
            }
            .time_right4 {
              height: 16px;
              line-height: 16px;
              border-radius: 2px;
              background-color: #ff9959;
              font-size: 10px;
              text-align: center;
              color: rgba(255, 255, 255, 100);
            }
          }
        }
      }

      .address {
        margin-top: 20px;
        width: 335px;
        height: 30px;
        line-height: 30px;
        border-radius: 2px;
        background-color: rgba(250, 250, 250, 100);

        .content {
          margin-left: 10px;
          color: rgba(189, 189, 189, 100);
          font-size: 12px;
          font-weight: 550;
        }
      }

      .user {
        width: 335px;
        height: 25px;
        margin-top: 20px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }

        .put {
          color: rgba(185, 190, 204, 100);
          font-size: 12px;
          text-align: left;
          margin-left: 8px;
          flex: 1;
        }

        .score {
          color: rgba(33, 33, 33, 100);
          font-size: 12px;
          font-weight: bolder;
        }
      }
    }
  }
}


</style>
