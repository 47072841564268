<!--活动报名支付-->
<template>
  <div class="container">
    <div class="container_content">
      <div class="container_content_top">
        <div class="top">
          <img :src="data.picUrl?data.picUrl:picUrl" alt="">
          <div class="right">
            <div class="content">
              {{ data.name }}
            </div>
            <div class="number">
              <div class="number_left">
                <div class="sign">
                  已报名
                </div>
                <div class="num" v-if="data.alreaySignUpNum===0">
                  <div class="num_item" >0</div>
                </div>
                <div class="num" v-else>
                  <div class="num_item" v-for="(num, i) in (data.alreaySignUpNum.toString().split(''))" :key="i">{{ num }}</div>
                </div>
              </div>
              <div class="number_right">
                限{{ data.limitNum }}人
              </div>
            </div>
            <div class="time">
              <div class="time_left">
                报名截止：{{data?.signEndTime?.substring(0, 10).replaceAll('-','.')}}
              </div>
              <div :class="[data.status==='0'?'time_right3':(data.status==='1'?'time_right2':(data.status==='2'?'time_right1':'time_right4'))]">
                {{ data.status==='0'?'已结束':(data.status==='1'?'进行中':(data.status==='2'?'报名中':'未开始')) }}
              </div>
            </div>
          </div>
        </div>
        <div class="address">
          <div class="content">
            {{ data?.addressDetail?.length>25?(data?.addressDetail?.slice(0,25)+'...'):data?.addressDetail }}
          </div>
        </div>
        <div class="user">
          <img :src="data?.pubUser?.avatar?(data?.pubUser?.avatar):avatarUrl" alt="">
          <div class="put">
            {{ data?.pubUser?.username }}发布于{{data.createTime.substring(0, 10).replaceAll('-','.')}}
          </div>
          <div class="score">
            可{{data.scoreType==='1'?'获得':'消耗'}}{{data.score}}积分

          </div>
        </div>
      </div>
      <div class="container_content_pay">
        <van-radio-group v-model="checked">
          <van-cell-group inset>
            <van-cell title="微信支付" clickable @click="checked = '1'">
              <template #icon>
                <img src="../../assets/images/shop/wxpay.png" alt="" class="custom-icon"/>
              </template>
              <template #right-icon>
                <van-radio name="1" icon-size="16px"/>
              </template>
            </van-cell>
            <van-cell title="余额支付" clickable @click="checked = '2'">
              <template #icon>
                <img src="../../assets/images/shop/leftpay.png" alt="" class="custom-icon"/>
              </template>
              <template #right-icon>
                <van-radio name="2"  icon-size="16px"/>
              </template>
            </van-cell>
            <van-cell title="支付宝支付" clickable @click="checked = '3'">
              <template #icon>
                <img src="../../assets/images/shop/all.png" alt="" class="custom-icon"/>
              </template>
              <template #right-icon>
                <van-radio name="3"  icon-size="16px"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <div class="container_content_bottom">
        <van-button type="warning" size="large" color="#FFD11A" @click="confirmPay">确认支付 &yen;{{data.money}}</van-button>
      </div>
    </div>
  </div>
</template>

<script setup name="ActivityPay">
  import {ref} from "@vue/reactivity";
  import {getDetail} from "../../api/activity";
  import {useRoute, useRouter} from "vue-router";
  import {getSignature} from "../../api/wxreq";
  import {signup} from "@/api/activity";
  import wx from "weixin-js-sdk";
  import {getOpenId} from "../../utils/auth";
  import {Toast} from "vant";
  import {authorize } from '@/api/user';
  import url from '@/utils/URL'
  const picUrl = ref('../../assets/images/activity/activity.png');
  const checked = ref('1')
  const data=ref({});
  const route = useRoute();
  const id = route.query.id;
  const company = ref('');
  const router = useRouter();
  const getAcDetail= async ()=>{
    let response=await getDetail(id);
    data.value=response.data
    /*data.value.starttime=data.value.starttime.replace('-','年');
    data.value.starttime=data.value.starttime.replace('-','月')+'日';
    data.value.endtime=data.value.endtime.replace('-','年');
    data.value.endtime=data.value.endtime.replace('-','月')+'日';*/
  }
  getAcDetail();

  const confirmPay = async ()=>{
    //const url = window.location.href
    let result = await getSignature({ url: url.fontUrl })
    if(result.code===200){
      const sign = result.data
      wx.config({
        debug: false,
        appId: sign.appid,
        timestamp: sign.timestamp,
        nonceStr: sign.noncestr,
        signature: sign.signature,
        jsApiList: [
          'chooseWXPay'
        ]
      })
      console.error(checked.value);
      let openid = getOpenId();
      if(openid == null && (checked.value == '1' || checked.value == '3')){
        let result = await authorize("shopPay");
        window.location.href = result;
      }
      console.error(checked.value);
      let payResult = await signup({aid:id,company:company.value,openid: openid,payType:checked.value,payWay:"js"});
      if(payResult?.code===200){
        console.log("payresult==",payResult);
        //微信支付
        if(checked.value == '1'){
          wx.ready(()=>{
            wx.chooseWXPay({
              timestamp: payResult?.data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
              nonceStr: payResult?.data.nonceStr, // 支付签名随机串，不长于 32 位
              package: payResult?.data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: payResult?.data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: payResult?.data.paySign, // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                router.go(-1)
              }
            });
          })
        }
        //支付宝支付
        if(checked.value == '3'){
          console.log("支付宝支付")
          const div = document.createElement('div')
          div.innerHTML = payResult.data //此处form就是后台返回接收到的数据
          document.body.appendChild(div)
          document.forms[0].submit()
          console.log("支付宝")
        }
        Toast(payResult.msg)
        router.push({name: "Activity"})
        //router.go(-1)
      }

    }


  }


</script>
<style lang="scss" scoped>
  .container{
    position: relative;
    height: calc(100vh - 50px)!important;
    overflow-y: scroll;
    &_content{
      padding: 10px 10px 10px 10px;
      &_pay{
        margin-top: 10px;
      }
      &_bottom{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 10px 10px;
      }
    }
    .container_content_top{
      height: 200px;
      background-color: #FFFFFF;
      border-radius: 6px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .top {
        display: flex;
        height: 90px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 4px;
          margin-left: 10px;
          margin-top: 10px;
        }

        .right {
          margin-left: 10px;
          margin-right:10px;

          .content {
            color: #212121;
            font-size: 14px;
            font-weight: 600;
            margin-top: 14px;
            margin-bottom: 12px;
          }

          .number {
            width: 245px;
            height: 24px;
            background-color: rgba(255, 145, 0, 0.05); /* 不支持线性的时候显示 */
            background-image: linear-gradient(to right, rgba(255, 145, 0, 0.05), #ffffff);
            display: flex;
            align-items: center;

            .number_left {
              display: flex;
              align-items: center;
              flex: 1;

              .sign {
                height: 16px;
                line-height: 16px;
                font-size: 12px;
                color: #F4BE48;
                margin-left: 5px;
              }

              .num {
                display: flex;
                margin-left: 5px;

                .num_item {
                  width: 16px;
                  border: 1px solid rgba(255, 145, 0, 20);
                  background-color: rgba(255, 255, 255, 100);
                  line-height: 16px;
                  border-radius: 2px;
                  margin-right: 3px;
                  height: 16px;
                  font-size: 12px;
                  font-weight: bolder;
                  text-align: center;
                  color: rgba(255, 145, 0, 100);
                }
              }
            }

            .number_right {
              height: 16px;
              line-height: 16px;
              font-size: 12px;
              color: rgba(244, 190, 72, 100);
            }
          }

          .time {
            display: flex;
            margin-top: 10px;

            .time_left {
              flex: 1;
              height: 16px;
              line-height: 16px;
              color: rgba(33, 33, 33, 100);
              font-size: 10px;
            }

            .time_right1 {
              height: 16px;
              line-height: 16px;
              border-radius: 2px;
              background-color: rgba(98, 212, 204, 100);
              font-size: 10px;
              text-align: center;
              color: rgba(255, 255, 255, 100);
            }
            .time_right2 {
              height: 16px;
              line-height: 16px;
              border-radius: 2px;
              background-color: rgba(0, 230, 118, 100);
              font-size: 10px;
              text-align: center;
              color: rgba(255, 255, 255, 100);
            }
            .time_right3 {
              height: 16px;
              line-height: 16px;
              border-radius: 2px;
              background-color: rgba(33, 33, 33, 100);
              font-size: 10px;
              text-align: center;
              color: rgba(255, 255, 255, 100);
            }
            .time_right4 {
              height: 16px;
              line-height: 16px;
              border-radius: 2px;
              background-color: #ff9959;
              font-size: 10px;
              text-align: center;
              color: rgba(255, 255, 255, 100);
            }
          }
        }
      }

      .address {
        margin-top: 20px;
        width: 335px;
        height: 30px;
        line-height: 30px;
        border-radius: 2px;
        background-color: rgba(250, 250, 250, 100);

        .content {
          margin-left: 10px;
          color: rgba(189, 189, 189, 100);
          font-size: 12px;
          font-weight: 550;
        }
      }

      .user {
        width: 335px;
        height: 25px;
        margin-top: 20px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }

        .put {
          color: rgba(185, 190, 204, 100);
          font-size: 12px;
          text-align: left;
          margin-left: 8px;
          flex: 1;
        }

        .score {
          color: rgba(33, 33, 33, 100);
          font-size: 12px;
          font-weight: bolder;
        }
      }
    }
    .van-button--large{
      height: 44px;
    }

    .van-cell-group--inset{
      margin: 0;
    }
    .custom-icon{
      margin-top: 3px;
      vertical-align: middle;
      height: 16px;
      margin-right: 4px;
    }
    .van-image{
      margin: 0 5px 0 5px;
      vertical-align: middle;
    }


  }
</style>
