<!---修改头像-->
<template>
  <div class="container">
      <div class="container_top">
        <div class="container_top_title">原始头像</div>
        <div class="container_top_pic"><img :src="user?.avatar" alt=""></div>
      </div>
      <div class="container_center">
        <div class="container_center_title">新头像</div>
        <div class="container_center_upload"><van-uploader v-model="fileList" :max-count="1" :before-read="beforeRead" :after-read="afterRead" multiple  @delete="deleteImage"/></div>
      </div>
      <div class="container_bottom">
            <van-button type="warning" size="large" color="#FFD11A" @click="saveData">保存</van-button>
      </div>
  </div>
</template>
<script setup name="ModifyHeader">

import {getUserInfo, updateUserInfo} from "@/api/user";
import {ref} from "vue";
import {uploadFiles} from "@/api/upload";
import {Toast} from "vant";
import router from "@/router";
const fileList = ref([]);
const files = ref([]);
const deleteImage=(file, detail)=>{
  files.value.splice(detail.index, 1)
}
const afterRead=async (file)=> {
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  formData.append('file', file.file)
  let response =await uploadFiles(formData);
  files.value.push(response.url)
}
const beforeRead=(file)=>{
  if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
    return true
  }
  Toast('请上传图片')
  return false
}
const user=ref({})
const getUser=async ()=>{
  let response=await getUserInfo();
  user.value=response.data
}
getUser()

const saveData = async ()=>{
  if (files.value.length===0) {
    Toast({
      message: '请上传新头像',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  let response=await updateUserInfo({avatar:files.value[0]});
  if(response){
    router.go(-1)
  }
}
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
  &_top{
    display: flex;
    flex-direction: column;
    padding: 30px 15px 30px 15px;
    background: #fff;
    &_title{
      font-size: 12px;
      color: #757575;
    }
    &_pic{
      margin-top: 15px;
    }
    &_pic img{
      height: 54px;
    }
  }
  &_center{
    margin-top: 10px;
    background: #fff;
     padding: 30px 15px 30px 15px;
    &_title{
      font-size: 12px;
      color: #757575;
    }
    &_upload{
      margin-top: 15px;
    }
  }
  &_bottom{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 10px 10px;
    }
}
.van-button--large{
    height: 44px;
}
</style>

