<template>
  <div class="container">
    <!--顶部第一部分-->
    <div class="container_info">
      <div class="container_info_item">
        <div class="container_info_item_name">
          申请原因
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="data.reason"
                input-align="left"
                type="textarea"
                placeholder="请输入"
            />
          </div>
          <!--          <img
                        src="../../assets/images/activity/edit.png"
                        alt=""
                    >-->
        </div>
      </div>
    </div>

  </div>

  <!-- 底部按钮 -->
  <div class="container_bottom">
    <van-button @click="saveData">提交申请</van-button>
  </div>
</template>
<script setup name="Submit">
import {ref} from "vue";
import {Toast} from "vant";
import {useRouter} from "vue-router";
import {submit} from "@/api/certificate";
const router = useRouter();

const data = ref({});


const saveData = async ()=>{
  if(data.value.reason == null){
    return Toast("请输入申请原因");
  }


  const  parameter = ref({})
  parameter.value = {
    reason:data.value.reason
  };
  let result = await submit(parameter.value);
  if(result?.code === 200){
    Toast(result?.msg);
    router.back()
  }else {
    Toast(result?.msg);
  }
}

</script>
<style lang="scss" scoped>
.van-background-default {
  background-color: rgba(245, 245, 245, 100);
  color: rgba(189, 189, 189, 100);
}

.van-background-checked {
  background-color: rgba(255, 145, 0, 100);
  color: rgba(255, 255, 255, 100);
}

.container {
  position: relative;
  height: calc(100vh - 130px) !important;
  overflow-y: scroll;
  padding: 0px 10px 10px 10px;


  &_info {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    padding: 5px 10px 18px 10px;
    height: 100px;
    &_item {
      display: flex;

      &_name {
        margin-top: 12px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
        flex: 2;
      }

      &_right {
        flex: 7;
        display: flex;
        align-items: center;
        &_des {
          line-height: 18px;
          color: rgba(33, 33, 33, 100);
          font-size: 16px;
          text-align: center;
          font-family: PingFangSC-medium;
          font-weight: bolder;
          flex: 1;
        }
      }
    }

    &_line {
      margin-top: 18px;
      margin-bottom: 18px;
      border: 1px solid #f5f5f5;
      width: 335px;
    }
  }
}
.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 50px;
    border-radius: 6px;
    background-color: #FFD11A;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
