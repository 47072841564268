<template>
  <div class="container">
    <!--顶部第一部分-->
    <div class="container_info">
      <div class="container_info_item">
        <div class="container_info_item_name">
          姓名
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="data.truename"
                input-align="left"
                placeholder="请输入真实姓名"
            />
          </div>
<!--          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >-->
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          性别
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="data.sex"
                input-align="left"
                placeholder="请选择性别"
                readonly
                @click="sexShow = true"

            />
          </div>
        <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          联系电话
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="data.phone"
                input-align="left"
                placeholder="请输入联系电话"
            />
          </div>
<!--          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >-->
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          常住地址
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="data.address"
                input-align="left"
                placeholder="请输入常驻地址"
            />
          </div>
<!--          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >-->
        </div>
      </div>
    </div>
<!--    底部-->
    <div class="container_info">
      <div class="container_info_item">
        <div class="container_info_item_name">
         选择站点
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="data.station"
                input-align="left"
                placeholder="请选择站点"
                readonly
                @click="stationShow = true"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          选择队伍
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="data.team"
                input-align="left"
                placeholder="请选择队伍"
                readonly
                @click="teamShow = true"
            />
          </div>
          <img
              src="../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_info_line"></div>
      <div class="container_info_item">
        <div class="container_info_item_name">
          申请说明
        </div>
        <div class="container_info_item_right">
          <div class="container_info_item_right_des">
            <van-field
                v-model="data.describe"
                input-align="left"
                placeholder="请输入申请说明"
            />
          </div>
<!--          <img
              src="../../assets/images/activity/edit.png"
              alt=""
          >-->
        </div>
      </div>
    </div>
  </div>
  <!-- 底部按钮 -->
  <div class="container_bottom">
    <van-button @click="saveData">保存</van-button>
  </div>
  <!--  选择性别-->
  <van-popup
      v-model:show="sexShow"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-picker
        title="选择性别"
        :columns="sexColumns"
        @confirm="onConfirmSex"
        @cancel="sexShow=false"
    />
  </van-popup>


  <!--  选择站点-->
  <van-popup
      v-model:show="stationShow"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-picker
        title="选择站点"
        :columns="stationColumns"
        @confirm="onConfirmStation"
        @cancel="stationShow=false"
    />
  </van-popup>



  <!--  选择队伍-->
  <van-popup
      v-model:show="teamShow"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-picker
        title="选择队伍"
        :columns="teamColumns"
        @confirm="onConfirmTeam"
        @cancel="teamShow=false"
    />
  </van-popup>


</template>
<script setup name="Apply">
import {ref} from "vue";
import { getUserInfo} from "@/api/user"
import {Toast} from "vant";
import {getStationList} from "../../api/home";
import {appley, getTeamList} from "../../api/user";
import {useRouter} from "vue-router";
const router = useRouter();

const data = ref({});
const sexShow = ref(false);
const sexColumns = ref([
  { id: 0, name: '男', text: "男" },
  { id: 1, name: '女', text: "女" }
]);

//站点
const stationShow = ref(false);
const stationColumns = ref([]);

//队伍
const teamShow = ref(false);
const teamColumns = ref([]);

const getInfo = async () => {
  let result = await getUserInfo();
  console.log("userINo111",result)
  if (result?.code === 200) {
    data.value.name = result.data.truename;
    data.value.phone = result.data.phone;
    if (result.data.sex != null) {
      if (result.data.sex === '0') {
        data.value.sex = '男'
      } else if (result.data.sex === '1') {
        data.value.sex = '女'
      }
    }
  }
}

const getStationListData = async () => {
  const query=ref({})
  query.value= {pageNum: 1,pageSize: 1000}
  let result = await getStationList(query.value);
  if(result?.code === 200){
    stationColumns.value=result.rows
    if(stationColumns.value.length>0){
      for (let e of stationColumns.value) {
        e.text = e.name
      }
    }
  }

}

//根据站点id查询队伍
const getTeamListByStationId  = async (stationId) =>{
  const query=ref({})
  query.value= {pageNum: 1,pageSize: 1000,stationId:stationId}
  let result = await getTeamList(query.value);
  if(result?.code === 200){
    teamColumns.value=result.rows
    if(teamColumns.value.length>0){
      for (let e of teamColumns.value) {
        e.text = e.name
      }
    }
  }
}

const saveData = async ()=>{
  if(data.value.truename == null){
    return Toast("请输入真实姓名");
  }
  if(data.value.sexId == null){
    return Toast("请选择性别");
  }
  if(data.value.phone == null){
    return Toast("请输入联系电话");
  }
  if(data.value.address == null){
    return Toast("请输入常住地址");
  }
  if(data.value.stationId == null){
    return Toast("请选择站点");
  }
  if(data.value.teamId == null){
    return Toast("请选择队伍");
  }


  const  parameter = ref({})
  parameter.value = {
    stationId:data.value.stationId,
    teamId:data.value.teamId,
    describe:data.value.describe,
    address:data.value.address,
    truename:data.value.truename,
    sex:data.value.sexId,
    phone:data.value.phone
  };
  let result = await appley(parameter.value);
  if(result?.code === 200){
    Toast(result?.msg);
    router.back()
  }else {
    Toast(result?.msg);
  }
}
getInfo();
getStationListData();
//性别的确认
const onConfirmSex = (val) => {
  sexShow.value = false
  data.value.sex = val.name
  data.value.sexId = val.id;
}
//站点的确认
const onConfirmStation = (val) => {
  stationShow.value = false
  data.value.station = val.name
  data.value.stationId = val.id
  getTeamListByStationId(val.id);
}
//队伍的确认
const onConfirmTeam = (val) => {
  teamShow.value = false
  data.value.team = val.name
  data.value.teamId = val.id
}

</script>
<style lang="scss" scoped>
.van-background-default {
  background-color: rgba(245, 245, 245, 100);
  color: rgba(189, 189, 189, 100);
}

.van-background-checked {
  background-color: rgba(255, 145, 0, 100);
  color: rgba(255, 255, 255, 100);
}

.container {
  position: relative;
  height: calc(100vh - 130px) !important;
  overflow-y: scroll;
  padding: 0px 10px 10px 10px;


  &_info {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    padding: 18px 10px 18px 10px;

    &_item {
      display: flex;

      &_name {
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
        flex: 2;
      }

      &_right {
        flex: 7;
        height: 20px;
        display: flex;
        align-items: center;
        &_des {
          line-height: 18px;
          color: rgba(33, 33, 33, 100);
          font-size: 16px;
          text-align: center;
          font-family: PingFangSC-medium;
          font-weight: bolder;
          flex: 1;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_line {
      margin-top: 18px;
      margin-bottom: 18px;
      border: 1px solid #f5f5f5;
      width: 335px;
    }
  }
}
.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #ffbf1e;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
