<template>
  <div class="container">
    <div class="container_card">
      <img
        :src="card.cardPicUrl"
        alt=""
      >
      <div class="container_card_order1">
        {{ card.order1 }}
      </div>
      <div class="container_card_order2">
        {{ card.order2 }}
      </div>
      <div class="container_card_order3">
        {{ card.order3 }}
      </div>
      <div class="container_card_order4">
        {{ card.order4 }}
      </div>
    </div>
    <div class="container_line"></div>
    <div class="container_info">
      <div class="container_info_name">
        姓名
      </div>
      <div class="container_info_content">
        {{ data.truename?(data.truename):(data.username) }}
      </div>
    </div>
    <div class="container_line"></div>
    <div class="container_info">
      <div class="container_info_name">
        性别
      </div>
      <div class="container_info_content">
        {{ data.sex=='0'?'男':'女' }}
      </div>
    </div>
    <div class="container_line"></div>
    <div class="container_info">
      <div class="container_info_name">
        联系电话
      </div>
      <div class="container_info_content">
        {{ data.phone }}
      </div>
    </div>
    <div class="container_line"></div>
    <div
      class="container_button"
      @click="addWxBag"
    >
      添加到微信卡包
    </div>
  </div>
</template>
<script setup >

import { ref } from "vue";
import { getVipCard } from "@/api/vipsetting";
import { getSignature, getSignatureCard } from "@/api/wxreq";
import url from "@/utils/URL";
import wx from "weixin-js-sdk";

const data = ref({});
const card = ref({});
const getCard = async () => {
  let response = await getVipCard();
  data.value = response.data
  card.value = response.data.cardDetail
}
getCard()

const addWxBag = async () => {
  let result = await getSignature({ url: url.fontUrl })
  console.log("aaa")

  console.log(result)
  const sign = result.data
  wx.config({
    debug: true,
    appId: sign.appid,
    timestamp: sign.timestamp,
    nonceStr: sign.noncestr,
    signature: sign.signature,
    jsApiList: [
      'addCard',
    ]
  })
  if (result.code == 200) {
    console.log("bbb")
    let response = await getSignatureCard({ cardId: card.value.cardId });
    console.log(response)
    wx.ready(function () {
      console.log("ccc")
      let obj;
      obj = {
        timestamp: response.data.timestamp,
        signature: response.data.signature,
        nonce_str: response.data.noncestr,
        card_id: response.data.cardId,
        api_ticket: response.data.ticket
      }
      alert(JSON.stringify(obj))
      wx.addCard({
        cardList: [{
          cardId: card.value.cardId,
          //cardId: response.data.cardId,
          cardExt: JSON.stringify(obj)
        }],
        success: function (res) {
          console.log(res)
          alert(JSON.stringify(res))
        },
        complete: function (res) {

          console.log(res)
          alert(JSON.stringify(res))
        }
      });

    });
  }

}

</script>
<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  height: 100%;
  padding: 20px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_card {
    width: 100%;
    height: 180px;
    position: relative;
    margin-bottom: 40px;
    img {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    &_order1 {
      position: absolute;
      top: 20px;
      left: 20px;
      text-align: left;
      z-index: 99;
    }
    &_order2 {
      position: absolute;
      top: 80px;
      right: 170px;
      text-align: right;
      z-index: 99;
    }
    &_order3 {
      position: absolute;
      top: 80px;
      left: 150px;
      text-align: left;
      z-index: 99;
    }
    &_order4 {
      position: absolute;
      top: 140px;
      right: 20px;
      text-align: right;
      z-index: 99;
    }
  }

  &_info {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    &_name {
      flex: 2;
      line-height: 18px;
      color: rgba(15, 15, 15, 100);
      font-size: 14px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
    &_content {
      flex: 5;
      line-height: 18px;
      color: rgba(174, 174, 174, 100);
      font-size: 14px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
  }
  &_line {
    margin-bottom: 20px;
    width: 100%;
    height: 1px;
    line-height: 20px;
    background-color: rgba(245, 245, 245, 100);
    text-align: center;
  }
  &_button {
    width: 100%;
    line-height: 18px;
    color: rgba(253, 127, 4, 100);
    font-size: 16px;
    text-align: center;
    font-family: PingFangSC-medium;
    font-weight: bolder;
  }
}
</style>
