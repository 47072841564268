<template>
  <div class="container">
    <div class="container_top">
      <div class="container_top_title">
        <span class="container_top_title_score"> {{ user == null ? 0 : user.score }}</span>
        <span class="container_top_title_info">积分</span>
      </div>
      <div class="container_top_icons">
        <div class="container_top_icons_item"><img
            src="../../assets/images/shop/record.png"
            alt=""
          ></div>
        <div
          class="container_top_icons_item"
          @click="toSearch"
        ><img
            src="../../assets/images/shop/search.png"
            alt=""
          ></div>
        <div
          class="container_top_icons_item"
          @click="toShopCart"
        ><img
            src="../../assets/images/shop/cart.png"
            alt=""
          ></div>
      </div>
    </div>

    <div class="container_content">
      <van-tabs
        v-model:active="active"
        title-active-color="#F4BE48"
        @click-tab="onClickTab"
      >
        <van-tab
          :title="item.name"
          v-for="(item,index) in tabs"
          :key="index"
        >
          <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
          >
            <van-list
              v-if="!isempty"
              v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <!-- <van-row gutter="10">

              <van-col
                span="12"
                v-for="(item, i) in list"
                :key="i"
                :class="[i == 0 ? 'even' : '']"
              >
                <van-cell>
                  <div class="container_content_item">
                    <van-image
                      :height="i ==0 ? '180' : '150'"
                      fit="cover"
                      src="https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg"
                    />
                    <div class="model">
                      <div>
                        <span class="container_content_item_title">露安适甜睡新生柔护面霜40g </span>
                      </div>
                      <div class="container_content_item_score">59积分 + 5.99元</div>
                    </div>
                  </div>
                </van-cell>
              </van-col>
            </van-row> -->
              <div class="container_content_listbox">
                <div
                  class="container_content_item"
                  v-for="(items) in list"
                  :key="items"
                  @click="toDetail(items.id)"
                >
                  <div class="container_content_item_pic">
                    <img
                      :src="items?.images[0]?.url"
                      alt=""
                    >
                  </div>
                  <div class="container_content_item_title">{{items.name}} </div>
                  <div
                    class="container_content_item_score"
                    v-if="items.payType==='1'"
                  >{{items.isRebate==='0' ? items.discountPrice : items.originalPrice}}元 </div>
                  <div
                    class="container_content_item_score"
                    v-else-if="items.payType==='2'"
                  >{{items.score}}积分 </div>
                  <div
                    class="container_content_item_score"
                    v-else-if="items.payType==='3'"
                  >{{items.isRebate==='0' ? items.discountPrice : items.originalPrice}}元+{{items.score}}积分 </div>
                  <div class="container_content_item_count">{{items.buyCount}}人兑换 </div>
                </div>
              </div>
              <!-- <div
              class="grid"
              v-masonry="containerId"
              transiton-duration="0.3s"
              item-selector=".card"
              gutter="5"
              fit-width="false"
              horizontal-order="true"
            >
              <div
                v-masonry-tile
                class="card"
                v-for="(items,i) in list"
                :key="i"
              >

                <div class="container_content_item_pic">
                  <img
                    src="../../assets/images/shop/g1.png"
                    alt=""
                  >
                </div>
                <div class="container_content_item_title">露安适甜睡新生柔护面霜40g </div>
                <div class="container_content_item_score">59积分 + 5.99元 </div>
                <div class="container_content_item_count">236人兑换 </div>
              </div>

            </div> -->

            </van-list>
          </van-pull-refresh>
          <van-empty
            description="暂无信息"
            v-if="isempty"
          />
        </van-tab>
      </van-tabs>
    </div>

  </div>
</template>
<script setup name="Shop">
import { reactive, ref, toRefs } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { getShopCates, getShopGoods } from "@/api/shop"
import { Toast } from "vant";
import { useStore } from 'vuex';
import {getUserInfo} from "@/api/user";
const store = useStore();
const router = useRouter();
const query = reactive({ queryParams: { pageSize: 10, pageNum: 1, cid: Number } })
const { queryParams } = toRefs(query)
const isempty = ref(false)
const active = ref(0);
const tabs = ref([]);
//tabs.value = ["精选推荐", "食品生鲜", "个护清洁", "母婴童装", "美妆护肤"];
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const user=ref({})
const getUser=async()=>{
  let response=await getUserInfo();
  user.value=response.data
}

getUser();
const getCategories = async () => {
  let result = await getShopCates();
  if (result?.code === 200) {
    //console.log(result)
    tabs.value = result.data;
    queryParams.value.cid = tabs.value[0].id;
    //onLoad(queryParams.value.cid);
  }
}

const onRefresh = () => {
  // 清空列表数据
  finished.value = false;
  list.value = [];
  // 重新加载数据
  // 将 loading 设置为 true，表示处于加载状态
  loading.value = true;
  refreshing.value = true;
  onLoad();
};

const onClickTab = () => {
  list.value = [];
  queryParams.value.cid = tabs.value[active.value].id;
  queryParams.value.pageNum = 1;
  onLoad()
};

const onLoad = async () => {
  if (refreshing.value) {
    list.value = [];
    refreshing.value = false;
  }
  let sid;
  if(store.state.stationId == 'undefined' || store.state.stationId == 'null' || store.state.stationId == null){
    sid ='';
  }else {
    sid = store.state.stationId;
  }
  queryParams.value.stationId = sid;
  let result = await getShopGoods(queryParams.value);
  if (result?.code === 200) {
    loading.value = false;
    list.value = result?.rows;
    finished.value = true;
    isempty.value = false;
    if (list.value.length < result?.total) {
      queryParams.value.pageNum++;
    }
  } else if (result?.code === 410) {
    loading.value = false;
    list.value = [];
    Toast("未查询到相关信息");
    finished.value = true;
    isempty.value = true;
  } else {
    loading.value = false;
    finished.value = true;
    isempty.value = true;
  }
}

getCategories();


// const onLoad = () => {
//   // 异步更新数据
//   // setTimeout 仅做示例，真实场景中一般为 ajax 请求
//   setTimeout(() => {
//     for (let i = 0; i < 10; i++) {
//       list.value.push(list.value.length + 1);
//     }

//     // 加载状态结束
//     loading.value = false;

//     // 数据全部加载完成
//     if (list.value.length >= 40) {
//       finished.value = true;
//     }
//   }, 1000);
// };

const toDetail = (id) => {
  router.push({ path: "shopDetail", query: { id: id } })
}

const toShopCart = () => {
  router.push({ path: "shopCart" })
}

const toSearch = () => {
  router.push({ path: "shopSearch" })
}
</script>
<style lang="scss" scoped>
.container {
  padding: 10px 10px;
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
  &_top {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding: 15px 10px 15px 10px;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    &_title {
      &_score {
        color: #000000;
        font-size: 20px;
        margin-right: 5px;
      }
      &_info {
        font-size: 12px;
        color: #9e9e9e;
      }
    }
    &_icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &_item {
        margin-right: 10px;
        border: #f5f5f5 1px solid;
        border-radius: 30px;
        padding: 10px 10px 10px 10px;
      }
      &_item img {
        width: 14px;
        vertical-align: middle;
        line-height: 0;
      }
    }
  }
  &_content {
    margin-top: 5px;
    &_listbox {
      column-count: 2;
      column-gap: 10px;
      // width: 100%;
      margin: 0 auto;
      padding-top: 10px;
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      // height: 100vh;
      //align-content: space-between;
      //column-count: 2;
      //overflow-x: hidden;
      //margin-right: 20px;
    }
    &_item {
      //position: relative;
      //width: 85%;
      //height: 280px;
      //width: calc(100% / 2 - 30px);
      box-sizing: border-box;
      -webkit-column-break-inside: avoid;
      break-inside: avoid;
      margin: 10px 0px 0px 0px;
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 10px 10px 10px 10px;
      overflow: hidden;

      &_pic {
        align-self: flex-start;
        width: 100%;
        height: 160px;
      }
      &_pic img {
        width: 100%;
        height: 160px;
      }
      &_title {
        margin-top: 10px;
        font-size: 12px;
        color: #212121;
      }
      &_score {
        margin-top: 10px;
        color: #ff9100;
        font-size: 16px;
      }
      &_count {
        margin-top: 10px;
        font-size: 10px;
        color: #bdbdbd;
      }
    }
  }
}
.even {
  height: 300px;
  //background: #ccc;
}
.van-col--12 {
  margin-top: 10px;
}
.grid-container {
  min-width: 70%;
  max-width: 100%;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
  grid-auto-rows: 300px;
}

.grid-item {
  height: fit-content;
  /*add the rest of your desired styling properties*/
}
// .van-list__loading {
//   clear: both;
// }
</style>
