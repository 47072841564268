import request from "@/utils/request";

export function getCertifList(params){
    return request({
        url: '/appreq/prof/getUserProfList',
        method: 'get',
        params
    })
}

export function getCertification(id){
    return request({
        url: '/appreq/prof/getProfById/'+id,
        method: 'get'
    })
}

//提交申请证书
export function submit(data){
    return request({
        url: '/appreq/prof/submit/',
        method: 'post',
        data
    })
}

