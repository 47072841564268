<template>
  <div  class="container">
    <div id="container_map" class="container_map"></div>
  </div>
</template>

<script setup>
import AMapLoader from "@amap/amap-jsapi-loader";
import {ref} from "@vue/reactivity";
import {Dialog} from "vant";
import {defineEmits } from "vue";

const emit = defineEmits(['toMap'])
const amap = ref(null);
const initMap = () => {
  AMapLoader.load({
    key: 'f59bd1414c7906ed19aadb98ee9ee086', // 设置您的key
    version: '2.0',
    plugins: ["Autocomplete","AMap.Geocoder"],
    AMapUI: {
      version: '1.1',
      plugins: []

    },
    Loca: {
      version: '2.0.0'
    }
  }).then((AMap) => {
    amap.value = AMap;
    console.log(AMap)
    let map = new AMap.Map('container_map', {
      viewMode: '3D',
      zoom: 5,
      zooms: [2, 22],
    })
    let geocoder = new AMap.Geocoder({
      // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
      city: '全国'
    })

    console.log(map)
    map.on('click', function (e) {
      let lnglat=[]
      lnglat[0]=e.lnglat.lng
      lnglat[1]=e.lnglat.lat
      console.log(e)
      getAddress(geocoder,lnglat)
    })
    getLocation(AMap);
  }).catch(e => {
    console.log(e)
  })
}
initMap()
const getAddress=(geocoder,lnglat)=>{

  geocoder.getAddress(lnglat, function(status, result) {
    if (status === 'complete' && result.info === 'OK') {
      // result为对应的地理位置详细信息
      let lng=lnglat[0]
      let lat=lnglat[1]
      let addressDetail = result.regeocode.formattedAddress;
      console.log(result.regeocode.formattedAddress);
      Dialog.alert({
        title: '详细地址',
        message: '是否选择该地点：'+result.regeocode.formattedAddress+" ?",
      }).then(() => {
        emit('toMap',{lng,lat,addressDetail})
      }).catch(()=>{
        emit('goBack',false)
      });
    }
  })
}
//解析定位结果
const getLocation = (amap) => {
  amap.plugin('AMap.Geolocation', function () {
    var geolocation = new amap.Geolocation({
      // 是否使用高精度定位，默认：true
      enableHighAccuracy: true,
      // 设置定位超时时间，默认：无穷大
      timeout: 10000,
      // 定位按钮的停靠位置的偏移量
      offset: [10, 20],
      //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
      zoomToAccuracy: true,
      //  定位按钮的排放位置,  RB表示右下
      position: 'RB'
    })

    geolocation.getCurrentPosition(function (status, result) {
      if (status == 'complete') {
        onComplete(result)
      } else {
        onError(result)
      }
    });

    function onComplete(data) {
      console.log(data);
      console.log(data.position);
    }

    function onError(data) {
      console.log(data)
    }
  })
}
</script>

<style lang="scss" scoped>
.container{
  padding: 10px;
  &_map{
    height: 100vh;
  }
}
</style>
