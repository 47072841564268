<!--余额充值界面-->
<template>
  <div class="container">
    <div class="container_box">
      <div class="container_box_top">
        <div class="container_box_top_price">
          <div class="container_box_top_price_info">
            &yen;{{money}}
          </div>
          <div class="container_box_top_price_title">
            账户余额
          </div>
        </div>
        <div class="container_box_buttons">

          <van-button
            size="normal"
            :class="[item.checked===true?'van-background-checked':'van-background-default']"
            @click="toogleBtn(index)"
            v-for="(item,index) in buttonList"
            :key="index"
          >{{item.name}}</van-button>

        </div>

        <div class="container_box_input">
          <div>
            <van-field
              v-model="value"
              label="&yen;"
              placeholder="请输入充值金额"
              input-align="left"
            />
          </div>
        </div>

      </div>
      <div class="container_pay">
        <van-radio-group v-model="checked">
          <van-cell
            title="微信支付"
            clickable
            @click="checked = '1'"
          >
            <template #icon>
              <img
                src="../../assets/images/shop/wxpay.png"
                alt=""
                class="custom-icon"
              />
            </template>
            <template #right-icon>
              <van-radio
                name="1"
                icon-size="16px"
              />
            </template>
          </van-cell>
          <van-cell
            title="支付宝支付"
            clickable
            @click="checked = '2'"
          >
            <template #icon>
              <img
                src="../../assets/images/center/alipay.png"
                alt=""
                class="custom-icon"
              />
            </template>
            <template #right-icon>
              <van-radio
                name="2"
                icon-size="16px"
              />
            </template>
          </van-cell>
        </van-radio-group>
      </div>

    </div>
    <div class="container_bottom">
      <van-button
        type="warning"
        size="large"
        color="#FFD11A"
        @click="saveData"
      >充值</van-button>
    </div>
  </div>
</template>
<script setup name="Recharge">
import { reactive, ref } from "@vue/reactivity";
import { getUserInfo, pay, authorize } from "@/api/user"
import { getRechargeSetting } from "@/api/recharge"
import wx from 'weixin-js-sdk'
import { getSignature } from "@/api/wxreq";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { getOpenId } from "../../utils/auth";
import url from '@/utils/URL'
const router = useRouter();
const value = ref('')
const checked = ref(null)
const buttonList = reactive([])
const toogleBtn = (index) => {
  checkMoney.value = buttonList[index].money;
  buttonList[index].checked = true
  for (let i in buttonList) {
    if (i != index) {
      buttonList[i].checked = false
    }
  }
}
const checkMoney = ref('');
//我的余额
const money = ref();
const getInfo = async () => {
  let result = await getUserInfo();
  if (result?.code === 200) {
    /* data.userInfo = result.data.*/
    if (result.data != null) {
      if (result.data.money != null) {
        money.value = result.data.money/100;
      } else {
        money.value = 0;
      }
    }
  }
}


const getSetting = async () => {
  let result = await getRechargeSetting();
  if (result?.code === 200) {
    if (result.data != null) {
      for (var i = 0; i < result.data.length; i++) {
        if (i == 0) {
          const item = {
            name: result.data[i].name,
            money: result.data[i].money,
            checked: true
          }
          buttonList.push(item);
        } else {
          const item = {
            name: result.data[i].name,
            money: result.data[i].money,
            checked: false
          }
          buttonList.push(item);
        }
      }
      checkMoney.value = buttonList[0].money;
    }
  }
}



getInfo();
getSetting();




//最终的金额(单位元)
const finalMoeny = ref();
const saveData = async () => {
  if (checked.value == null) {
    Toast("请选择支付方式")
    return;
  }
  if (value.value != null && value.value != "") {
    finalMoeny.value = value.value;
  } else {
    finalMoeny.value = checkMoney.value;
  }
  //const url = window.location.href
  let result = await getSignature({ url: url.fontUrl })
  if (result.code === 200) {
    const sign = result.data
    wx.config({
      debug: false,
      appId: sign.appid,
      timestamp: sign.timestamp,
      nonceStr: sign.noncestr,
      signature: sign.signature,
      jsApiList: [
        'chooseWXPay'
      ]
    })
    let openid = getOpenId();
    if (openid == null) {
      let result = await authorize("recharge");
      window.location.href = result;
      return;
    }
    let payResult = await pay({ money: finalMoeny.value, openid: openid, payway: 'js' ,payType:checked.value=='1'?'wx':'ali'});
    if (payResult?.code === 200) {
      console.log("payresult==", payResult);
      //微信
      if (checked.value == '1') {
        wx.ready(() => {
          wx.chooseWXPay({
            timestamp: payResult?.data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: payResult?.data.nonceStr, // 支付签名随机串，不长于 32 位
            package: payResult?.data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: payResult?.data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: payResult?.data.paySign, // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              console.log("res===", res)
              Toast("支付成功")
              router.push({ name: "center" })
            }
          });
        })
      }
      //支付宝
      if (checked.value == '2') {
        const div = document.createElement('div')
        div.innerHTML = payResult.data //此处form就是后台返回接收到的数据
        document.body.appendChild(div)
        document.forms[0].submit()
        console.log("支付宝")
      }
    }

  }

}


// const checked = ref(false);
// const activeIcon = 'https://fastly.jsdelivr.net/npm/@vant/assets/user-active.png';
// const inactiveIcon = 'https://fastly.jsdelivr.net/npm/@vant/assets/user-inactive.png';
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
  &_box {
    padding: 10px 10px 10px 10px;
    &_top {
      background: #fff;
      padding: 25px 10px 25px 10px;
      &_price {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_info {
          color: #ff9100;
          font-size: 28px;
        }
        &_title {
          font-size: 12px;
          color: #212121;
          margin-top: 10px;
          font-weight: bold;
        }
      }
    }
    &_buttons {
      margin-top: 56px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    &_input {
      margin-top: 30px;
    }
  }
  &_pay {
    margin-top: 10px;
    border-radius: 6px;
  }
  &_bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 10px;
  }
}
.img-icon {
  height: 20px;
}
.van-background-default {
  background-color: rgba(245, 245, 245, 100);
  color: rgba(189, 189, 189, 100);
  margin-top: 10px;
  width: 30%;
  margin-right: 5px;
}

.van-background-checked {
  background-color: rgba(255, 145, 0, 100);
  color: rgba(255, 255, 255, 100);
  margin-top: 10px;
  width: 30%;
  margin-right: 5px;
}
.custom-icon {
  margin-top: 3px;
  vertical-align: middle;
  height: 16px;
  margin-right: 4px;
}
</style>
