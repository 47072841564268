<template>
  <div class="container">
    <ActivityDetail :activity="activity"></ActivityDetail>

    <!--  按钮  -->
    <div class="container_bottom">
      <van-button v-if="isSign===false" @click="confirmBtn(id)" :disabled="activity.status!=='1'">现场签到</van-button>
      <van-button v-if="isSign===true&&isSignOff===false" @click="confirmBtnOff(id)" :disabled="activity.status!=='1'">现场签退</van-button>
    </div>
  </div>
</template>

<script setup>
import ActivityDetail from "@/components/ActivityDetail";
import {Toast} from "vant";
import {useRoute, useRouter} from "vue-router";
const router = useRouter();
const route = useRoute();
const id = route.query.id;
console.log(id)
import {checkSign, checkSignOff, getDetail, sign, signoff} from "@/api/activity";
import {ref} from "vue";
const activity=ref({});
const isSign=ref(false);
const isSignOff=ref(false);
const getAcDetail= async ()=>{
  let response=await getDetail(id);
  activity.value=response.data
  activity.value.starttime=activity.value.starttime.replace('-','年');
  activity.value.starttime=activity.value.starttime.replace('-','月')+'日';
  activity.value.endtime=activity.value.endtime.replace('-','年');
  activity.value.endtime=activity.value.endtime.replace('-','月')+'日';
}
const getCheckSign=async ()=>{
  let response=await checkSign(id);
  isSign.value=response.data.isSign
}
const getCheckSignOff=async ()=>{
  let response=await checkSignOff(id);
  isSignOff.value=response.data.isSignOff
}
getCheckSign()
getCheckSignOff()
getAcDetail();
const confirmBtn=(val)=>{
  Toast.loading({
    message: '签到中...',
    forbidClick: true,
  })
  setTimeout(async ()=>{
    let response =await sign({aid:val});
    if(response!=null){
      router.push({
        path: "/onSiteCheckIn",
        query: {
          sid: response.data.id,
          type:"1"
        },
      });
    }

  },2000)
}
const confirmBtnOff=(val)=>{
  Toast.loading({
    message: '签退中...',
    forbidClick: true,
  })
  setTimeout(async ()=>{
    let response =await signoff({aid:val});
    if(response!=null){
      router.push({
        path: "/onSiteCheckIn",
        query: {
          sid: response.data.id,
          type:"2"
        },
      });
    }

  },2000)
}

</script>

<style lang="scss" scoped>
  .container {
    height: calc(100vh - 100px)!important;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_content{
      margin-top: 10px;
      width: 355px;
      height: 157px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_top{
        margin: 10px;
        display: flex;
        img{
          width: 80px;
          height: 80px;
          margin-right: 10px;
        }
        &_right{
          width: 245px;
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &_first{
            display: flex;
            width: 100%;
            height: 48px;
            &_name{
              height: 48px;
              width: 170px;
              color: rgba(33, 33, 33, 100);
              font-size: 14px;
              font-weight: bolder;
              text-align: left;
              font-family: PingFangSC-semiBold;
            }
            &_type{
              margin-left: 21px;
              width: 59px;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              font-weight: bolder;
              color: rgba(244, 190, 72, 100);
              border-radius: 2px;
              background-color: rgba(244, 190, 72, 0.1);
              text-align: center;
            }
          }
          &_second{
            display: flex;
            width: 100%;
            height: 16px;
            margin-top: 16px;
            &_type{
              width: 38px;
              height: 16px;
              line-height: 20px;
              border-radius: 2px;
              background-color: rgba(98, 212, 204, 100);
              color: rgba(255, 255, 255, 100);
              font-size: 10px;
              text-align: left;
              font-weight: bolder;
            }
            &_par{
              margin-left: 10px;
              color: rgba(244, 190, 72, 100);
              font-size: 12px;
              text-align: left;
              font-weight: bolder;
            }
            &_num{
              display: flex;
              margin-left: 55px;
              width: 94px;
              font-size: 12px;
              height: 16px;
              &_one{
                color: rgba(189, 189, 189, 100);

                text-align: left;
              }
              &_two{
                margin-left: 3px;
                color: rgba(255, 145, 0, 100);
                text-align: left;
              }
              &_three{
                margin-left: 2px;
                color: rgba(189, 189, 189, 100);
                text-align: left;
              }
            }
          }
        }
      }
      &_line{
        border: 1px solid #F5F5F5;
        width: 335px;
      }
      &_score{
        width: 335px;
        height: 16px;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        &_left{
          flex: 1;
          width: 100px;
          text-align: left;
        }
        &_right{
          display: flex;
          img{
            width: 16px;
            height: 16px;
          }
          &_send{
            margin-left: 2px;
            color: rgba(244, 190, 72, 100);
          }
        }
      }
    }
    &_user{
      margin-top: 15px;
      width: 355px;
      height: 119px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_top{
        margin: 10px;
        display: flex;
        height: 40px;
        width: 335px;
        &_left{
          flex: 1;
          display: flex;
          img{
            height: 40px;
            width: 40px;
            border-radius: 25px;
          }
          &_address{
            margin-top: 10px;
            line-height: 20px;
            height: 20px;
            color: rgba(51, 51, 51, 100);
            font-size: 16px;
            text-align: left;
            margin-left: 10px;
            font-weight: bolder;
            font-family: PingFangSC-medium;
          }
        }
        &_right{
          margin-top: 10px;
          line-height: 20px;
          height: 20px;
          color: rgba(189, 189, 189, 100);
          font-size: 14px;
          text-align: left;
          font-family: PingFangSC-regular;
        }
      }
      &_line{
        border: 1px solid #F5F5F5;
        width: 335px;
      }
      &_bottom{
        width: 335px;
        height: 18px;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        &_left{
          color: rgba(51, 51, 51, 100);
          flex: 1;
          text-align: left;
          font-family: PingFangSC-regular;
        }
        &_right{
          display: flex;
          img{
            width: 18px;
            height: 18px;
          }
          &_phone{
            margin-left: 6px;
            color: rgba(51, 51, 51, 100);
            font-weight: bolder;
            font-family: PingFangSC-medium;
          }
        }
      }
    }
    &_details{
      width: 355px;
      margin-top: 15px;
      margin-bottom: 50px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(16, 16, 16, 100);
      display: flex;
      flex-direction: column;
      align-items: center;
      &_address{
        height: 54px;
        width: 100%;
        opacity: 0.9;
        background: url("../../../assets/images/activity/background.png");
        display: flex;
        img{
          width: 18px;
          height: 18px;
          margin: 18px 5px 18px 10px;
          opacity: 1;
          border-radius: 6px 6px 0 0;
        }
        &_right{
          height: 18px;
          opacity: 1;
          color: rgba(255, 145, 0, 100);
          font-size: 14px;
          text-align: left;
          margin-top: 18px;
          margin-bottom: 18px;
          font-family: PingFangSC-semiBold;
          font-weight: bolder;
        }
      }
      &_content{
        width: 335px;
        &_top{
          margin-top: 18px;
          margin-bottom: 18px;
          height: 18px;
          line-height: 18px;
          width: 335px;
          display: flex;
          img{
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
          &_right{
            color: rgba(33, 33, 33, 100);
            font-size: 14px;
            text-align: left;
            font-family: PingFangSC-semiBold;
            font-weight: bolder;
          }
        }
        &_line{
          border: 1px solid #F5F5F5;
          width: 335px;
        }
        &_description{
          width: 335px;
          text-align: justify;
          font-size: 12px;
          font-family: PingFangSC-regular;
          line-height: 20px;
          margin-top: 15px;
          margin-bottom: 30px;
        }
        &_line{
          border: 1px solid #F5F5F5;
          width: 335px;
        }
        &_user{
          margin-bottom: 15px;
          margin-top: 15px;
          width: 335px;
          height: 25px;
          display: flex;
          img{
            width: 25px;
            height: 25px;
            margin-right: 8px;
            border-radius: 50%;
          }
          &_right{
            height: 25px;
            line-height: 25px;
            color: rgba(185, 190, 204, 100);
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-regular;
          }
        }
      }
    }
  }
  .container_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    line-height: 20px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
    font-family: Arial;

    .van-button {
      margin: 10px;
      width: 355px;
      height: 44px;
      border-radius: 6px;
      background-color: #FFBF1E;
      text-align: center;
      color: rgba(255, 255, 255, 100);
      font-size: 14px;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
    }
  }
</style>
