<template>
  <div class="container_box">
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh" >
    <van-list
        v-if="!isempty"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="container"
    >
    <template v-for="(item,index) in list" :key="index">
     <div class="container_item" @click="toCertificateDetails(item.id)">
        <img src="../../assets/images/center/certificate.png" alt="">
        <div class="container_item_right">
          <div class="container_item_right_title">
            {{item.profName}}
          </div>
          <div class="container_item_right_bottom">
            <div class="container_item_right_bottom_num">
               {{item.profNum}}
            </div>
            <div class="container_item_right_bottom_time">
               {{item.createTime}}
            </div>
          </div>
        </div>
      </div>
    </template>
    </van-list>
  </van-pull-refresh>
  <van-empty
      description="暂无信息"
      v-if="isempty"
  />
  </div>
  <div class="container_bottom">
    <van-button
        type="warning"
        size="large"
        color="#FFD11A"
        @click="saveData"
    >申请证书</van-button>
  </div>
</template>

<script setup name="Certificate">
import { Toast } from "vant";
import {reactive, ref, toRefs} from "vue";
import {useRouter} from "vue-router";
import { getCertifList } from "../../api/certificate";
const isempty = ref(false)
const router = useRouter();
const  toCertificateDetails=(id)=> {
  router.push({
    path: "/certificateDetails",
    query: {
      id: id
    }
  });
}
const list = ref([]);
const querydata = reactive({queryParams:{pageSize: 10, pageNum: 1}});
const {queryParams} = toRefs(querydata);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const onRefresh = () => {
  finished.value = false;
  loading.value = true;
  refreshing.value = true;
  queryParams.value.pageNum = 1;
  onLoad()
};
const onLoad = async () => {
 let result =  await getCertifList(queryParams);
 console.log("result===",result)
 if(result.code === 200){
   loading.value = false;
   refreshing.value = false;
   finished.value = true;
   list.value = result.rows;
   if (list.value.length < result?.total) {
      queryParams.value.pageNum++;
    }
 }else if (result?.code === 410) {
    loading.value = false;
    list.value = [];
    Toast("未查询到相关信息");
    finished.value = true;
    isempty.value = true;
  } else {
    loading.value = false;
    finished.value = true;
    isempty.value = true;
  }
};

const saveData = ()=>{
  router.push({path: "submit"})
};

</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 90px)!important;
  overflow-y: scroll;
  padding: 15px 10px 15px 10px;
  &_bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 10px;
  }
  &_item{
    margin-bottom: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    align-items: center;
    padding: 10px;
    img{
      width: 40px;
      height: 40px;
      background-color: rgba(255, 255, 255, 100);
    }
    &_right{
      width: 295px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      &_title{
        line-height: 14px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-semiBold;
      }
      &_bottom{
        margin-top: 14px;
        display: flex;
        align-items: center;
        line-height: 12px;
        &_num{
          flex: 1;
          color: rgba(158, 158, 158, 100);
          font-size: 12px;
          text-align: left;
          font-family: PingFangSC-regular;
        }
        &_time{
          color: rgba(224, 224, 224, 100);
          font-size: 12px;
          text-align: right;
          font-family: PingFangSC-regular;
        }
      }
    }
  }
}
</style>
