<template>
  <div class="container">
    <ActivityDetail :activity="activity"></ActivityDetail>
    <!-- todo   -->
    <!--  按钮  -->
    <div class="container_bottom" v-if="active==0">
      <van-button @click="confirmBtn">认领项目</van-button>
    </div>
  </div>
</template>

<script setup>
import ActivityDetail from '../../../components/ActivityDetail.vue';
import {Dialog, Toast} from "vant";
import {useRoute} from "vue-router";
const route = useRoute();
const id = route.query.id;
const active = route.query.active;
console.log(id)
import {claim, getDetail} from "@/api/activity";
import {ref} from "vue";
const activity=ref({});
const getAcDetail= async ()=>{
  let response=await getDetail(id);
  activity.value=response.data
  activity.value.starttime=activity.value.starttime.replace('-','年');
  activity.value.starttime=activity.value.starttime.replace('-','月')+'日';
  activity.value.endtime=activity.value.endtime.replace('-','年');
  activity.value.endtime=activity.value.endtime.replace('-','月')+'日';
}
getAcDetail();
const confirmBtn=()=>{
  Dialog.confirm({
    title: '认领活动',
    message:
        '为您的队伍认领当前长期活动',
  })
      .then(async() => {
        let response =await claim({aid:id});
        if(response){
          Toast("认领成功！")
        }
      })
      .catch(() => {
        // on cancel
      });
}

</script>

<style lang="scss" scoped>
  .container {
    height: calc(100vh - 100px)!important;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .container_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    line-height: 20px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
    font-family: Arial;

    .van-button {
      margin: 10px;
      width: 355px;
      height: 44px;
      border-radius: 6px;
      background-color: #FFBF1E;
      text-align: center;
      color: rgba(255, 255, 255, 100);
      font-size: 14px;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
    }
  }
</style>
