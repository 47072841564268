<template>
    <div class="container">
        <div class="container_paystatus">
            <div class="container_paystatus_pic">
                <img src="../../assets/images/shop/success.png" alt="">
            </div>
            <div class="container_paystatus_title">支付成功
            </div>
        </div>

        <div class="container_orderInfo">
            <div class="container_orderInfo_row">
            <div class="container_orderInfo_title">订单编号</div>
            <div class="container_orderInfo_info">202205200032</div>
            </div>
            <div class="container_orderInfo_row">
            <div class="container_orderInfo_title">支付积分</div>
            <div class="container_orderInfo_info">15</div>
            </div>
            <div class="container_orderInfo_row">
            <div class="container_orderInfo_title">实付金额</div>
            <div class="container_orderInfo_info">&yen;1.79</div>
            </div>
            <div class="container_orderInfo_row">
            <div class="container_orderInfo_title">支付方式</div>
            <div class="container_orderInfo_info">微信支付</div>
            </div>
            
        </div>
        <div class="container_orderInfo">
            <div class="container_orderInfo_row">
            <div class="container_orderInfo_title">收货人</div>
            <div class="container_orderInfo_info">张文涛</div>
            </div>
            <div class="container_orderInfo_row">
            <div class="container_orderInfo_title">收货地址</div>
            <div class="container_orderInfo_info">长河街道广川大道星凯国际小区C区12号楼</div>
            </div>
        </div>

    </div>
</template>

<script setup name="ShopPayStatus">

</script>

<style lang="scss" scoped>
.container{
    height: calc(100vh - 50px)!important;
    overflow-y: scroll;
    &_paystatus{
        display: flex;
        flex-direction: column;
        align-items: center;
        &_pic{
            margin-top: 50px;
        }
        &_pic img{
            height: 60px;
        }
        &_title{
            margin-top: 25px;
            font-size: 20px;
            color: #101010;
        }
    }
    &_orderInfo{
        margin-top: 40px;
        padding: 10px 30px 0 30px;
        display: flex;
        flex-direction: column;
        &_row{
            display: flex;
            flex-direction: row;
            margin-top: 20px;
        }
        &_title{
            color: #9E9E9E;
            font-size: 14px;
            flex: 2;
        }
        &_info{
            font-size: 14px;
            color: #424242;
            flex: 7;
        }
    }
}
</style>